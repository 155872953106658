import { takeLatest, take, all, call, put, select } from 'redux-saga/effects';
import { getFirebase } from 'react-redux-firebase';
import update from 'immutability-helper';
import AlgoliaClient from 'utils/algoliaService';
import Config from 'utils/getEnvConfig';
import ctf from 'utils/contentfulService';
import { getLocalData } from 'utils/localDataStore';
import { maybeContentfulReviewStatus } from 'utils/api';
import _isEmpty from 'lodash/isEmpty';
import _clone from 'lodash/clone';
import _has from 'lodash/has';
import _forEach from 'lodash/forEach';
import _get from 'lodash/get';
import _flatten from 'lodash/flatten';
import _uniqBy from 'lodash/uniqBy';
import { setAuthModalState, storeItemForSignIn } from 'containers/Auth/actions';
import { SET_PROFILE_FILTERS } from 'containers/Auth/constants';
import {
  makeSelectAnonymousId,
  makeSelectProfile,
  makeSelectProfileFilters,
} from 'containers/Auth/selectors';
import { setNPSVisibility } from 'containers/Main/actions';
import {
  GET_CLIENT_DETAILS_SUCCESS,
  GET_SITE_COPY_SUCCESS,
} from 'containers/Main/constants';
import {
  makeSelectAudienceTagsRelations,
  makeSelectClientDetails,
  makeSelectSiteCopy,
  makeSelectLanguage,
  makeSelectSiteCopyLanguage,
  makeSelectSiteConfig,
  isAIDescriptionEnabled,
} from 'containers/Main/selectors';
import {
  getAlgoliaAssessmentQuery,
  getAlgoliaClientQuery,
  getAlgoliaRatingQuery,
  getAlgoliaTopicsQuery,
  getTopicsSlugQuery,
  getContentfulLocaleFilter,
} from 'containers/Main/utils';
import { DEFAULT_CONFIG } from 'components/NPSSurvey/constants';
import { getImageFile, isBot } from 'utils/stringUtils';
import { getAlgoliaLocaleCodeGenerator } from 'utils/localeUtils';
import { getTranslatedQuery } from 'utils/translationService';
import {
  processing,
  algoliaProcessing,
  algoliaNextPageProcessing,
  algoliaTopicsProcessing,
  getSavedResources,
  getSavedResourcesResult,
  saveResourceResult,
  rateResourceResult,
  getAlgoliaResourcesResult,
  getAlgoliaTopicFacetsResult,
  getResourceReviewResult,
  reviewResourceResult,
  voteHelpfulResourceResult,
  getHelpfulRatings,
  getHelpfulRatingsResult,
  getRecommendedResourcesResult,
  getUserReviews,
  getUserReviewsSuccess,
  fetchingUserReviews,
  submittingFeedback,
  getUserHistoryResult,
} from './actions';
import {
  GET_SAVED_RESOURCES,
  GET_ALGOLIA_RESOURCES,
  GET_ALGOLIA_TOPIC_FACETS,
  SAVE_RESOURCE,
  RATE_RESOURCE,
  REVIEW_RESOURCE,
  GET_RESOURCE_REVIEW,
  VOTE_HELPFUL_RESOURCE,
  GET_HELPFUL_RATINGS,
  GET_RECOMMENDED_RESOURCES,
  GET_USER_REVIEWS,
  GET_USER_HISTORY,
} from './constants';
import {
  makeSelectAlgoliaTopicsQuery,
  makeSelectSavedResources,
} from './selectors';
import {
  getClientAudienceFilters,
  getFeedbackDBCollectionNameByType,
  getPopulateReview,
  getResourcesIndex,
  parseAvailableInFilters,
  sortByTopicsRelevance,
} from './utils';

const {
  ALGOLIA: { RESOURCES_AVG_RATED_INDEX, RESOURCES_RELEVANCE_INDEX, NEWS_INDEX },
} = Config;

const FAVORITES_ATTRIBUTES_TO_RETRIEVE_LOCALIZED = [
  'title',
  'name',
  'article',
  'subtitle',
  'shortDescription',
];

const FAVORITES_ATTRIBUTES_TO_RETRIEVE = [
  'expandedType',
  'type',
  'slug',
  'imageUrl',
  'image',
  'calculatedUserRating',
  'calculatedExpertRating',
  'avgRating',
  'allTopics',
  'listenNotesId',
  'alternateImage',
  'labels',
  'url',
  'minutes',
  'blog',
  'type',
  'subtype',
  'organization',
  'description',
  'cardHighlights',
  'audience',
  'tags',
  'highlights',
  'resourcesImages',
  'wordCount',
];

const COSTS_ARRAY = [
  { label: 'Free', value: 'Free' },
  { label: '$', value: '$' },
];

function* getSavedResourcesSaga() {
  try {
    yield put(processing(true));

    const userID = yield getFirebase().auth().currentUser.uid;
    const savedResourcesDocs = yield getFirebase()
      .firestore()
      .collection('user_resources')
      .doc(userID)
      .collection('saved_resources')
      .get();
    const savedResourcesIds = savedResourcesDocs.docs.map(doc => doc.id);
    const savedResourcesData = savedResourcesDocs.docs.map(doc => {
      const data = doc.data();
      return {
        ...data,
        objectID: doc.id,
        id: doc.id,
        expandedType: data.type,
      };
    });

    const globalListsData = savedResourcesData.filter(
      item => item.type === 'Lists' && !_isEmpty(item.topics),
    );

    const locale = yield getAlgoliaLocaleCodeGenerator();
    const prefix = locale && locale !== 'en-US' ? `${locale}_` : '';

    const attributesToRetrieve = FAVORITES_ATTRIBUTES_TO_RETRIEVE.concat(
      prefix
        ? FAVORITES_ATTRIBUTES_TO_RETRIEVE_LOCALIZED.map(
            attr => `${prefix}${attr}`,
          )
        : FAVORITES_ATTRIBUTES_TO_RETRIEVE_LOCALIZED,
    );

    const index = AlgoliaClient.initIndex(RESOURCES_AVG_RATED_INDEX);
    const { hits } = yield index.search('', {
      filters: savedResourcesIds.map(id => `objectID:'${id}'`).join(' OR '),
      attributesToRetrieve,
      attributesToHighlight: [],
      hitsPerPage: 1000,
    });
    const parsedHits = hits.map(hit => {
      const { timestamp } = savedResourcesData.find(
        item => item.id === hit.objectID,
      );

      return {
        ...hit,
        timestamp,
      };
    });
    const restSavedResources = savedResourcesData.filter(
      r => !parsedHits.some(hit => hit.objectID === r.id),
    );

    const parsedGlobalLists = [];
    if (!_isEmpty(globalListsData)) {
      const siteCopy = (yield select(makeSelectSiteCopy())).find(
        item => item.slug === 'filters-translations',
      );

      const localeFilters = yield call(getContentfulLocaleFilter);
      const response = yield ctf.getEntries({
        content_type: 'list',
        'sys.id[in]': globalListsData.map(item => item.resourceID).join(','),
        ...maybeContentfulReviewStatus(),
        ...localeFilters,
      });

      globalListsData.forEach(list => {
        const entry = response.items.find(
          item => item.sys.id === list.resourceID,
        );
        list.topics.forEach(topic => {
          let title = _get(entry, 'fields.title', '');
          if (_get(entry, 'fields.title', '').includes('<topic>')) {
            title = _get(entry, 'fields.title', '').replace(
              '<topic>',
              _get(siteCopy, ['pageCopy', 'topics', topic.slug]),
            );
          }
          const slug = `${list.slug}/${topic.slug}`;
          parsedGlobalLists.push({
            title,
            subtitle: _get(entry, 'fields.subtitle'),
            shortDescription: _get(entry, 'fields.shortDescription'),
            imageUrl: getImageFile(entry.fields),
            slug,
            timestamp: list.timestamp,
            type: 'Lists',
            expandedType: 'Lists',
            objectID: list.resourceID,
          });
        });
      });
    }

    yield put(
      getSavedResourcesResult([
        ...parsedHits,
        ...parsedGlobalLists,
        ...restSavedResources,
      ]),
    );
  } catch (error) {
    yield put(getSavedResourcesResult([]));
  } finally {
    yield put(processing(false));
  }
}

function* getAlgoliaResourcesSaga({ payload }) {
  const {
    typeFilters,
    allTopicFilters,
    queryFilter = '',
    costFilters,
    durationFilters,
    focusFilters,
    audienceFilters,
    availableInFilters,
    presentation = 'mixed',
    hitsPerPage = 12,
    page = 0,
    isMobileEmbedded = false,
  } = payload;
  try {
    if (page === 0) yield put(algoliaProcessing(true));
    else yield put(algoliaNextPageProcessing(true));

    const language = yield select(makeSelectLanguage());
    let siteCopy = yield select(makeSelectSiteCopy());
    const siteCopyLanguage = yield select(makeSelectSiteCopyLanguage());
    if (siteCopyLanguage !== language) {
      yield take(GET_SITE_COPY_SUCCESS);
      siteCopy = yield select(makeSelectSiteCopy());
    }
    const [resourceItemSiteCopy] = siteCopy.filter(
      el => el.slug === 'resource-item-page',
    );
    const [resourcesSiteCopy] = siteCopy.filter(el => el.slug === 'resources');

    const resourceTypeMapping = _get(
      resourceItemSiteCopy,
      'pageCopy.algoliaResourceTypeMapping',
      {},
    );
    const resourceTypeReverseMapping = {};
    Object.keys(resourceTypeMapping).forEach(key => {
      resourceTypeReverseMapping[key] = key;
      resourceTypeReverseMapping[resourceTypeMapping[key]] = key;
    });

    const clientShortName = getLocalData('brand');
    const hasClientShortName =
      !_isEmpty(clientShortName) && clientShortName !== 'none';

    let clientInfo;
    if (hasClientShortName) {
      clientInfo = yield select(makeSelectClientDetails());
      if (_isEmpty(clientInfo) || clientInfo.language !== language) {
        yield take(GET_CLIENT_DETAILS_SUCCESS);
        clientInfo = yield select(makeSelectClientDetails());
      }
    }

    const clientExcludedResourceTypes =
      _get(clientInfo, 'excludeResourceTypes') || [];
    const prioritizeClientResources = _get(
      clientInfo,
      'metadata.prioritizeClientResources',
      false,
    );
    const locale = yield getAlgoliaLocaleCodeGenerator();
    const prefix = locale && locale !== 'en-US' ? `${locale}_` : '';

    const finalQuery =
      locale !== 'en-US'
        ? yield getTranslatedQuery(queryFilter, locale)
        : queryFilter;

    let definitiveIndex =
      queryFilter !== ''
        ? RESOURCES_RELEVANCE_INDEX
        : RESOURCES_AVG_RATED_INDEX;
    if (definitiveIndex === RESOURCES_AVG_RATED_INDEX) {
      const resourcesWeightings = _get(
        clientInfo,
        'metadata.algoliaResourcesWeight',
      );

      definitiveIndex = getResourcesIndex(resourcesWeightings);
    }

    const siteConfig = yield select(state => state.main.siteConfig);
    const featuresConfig = siteConfig.find(item => item.title === 'Features');
    const defaultSearchableAttributes = finalQuery
      ? [
          `${prefix}name`,
          `${prefix}title`,
          `${prefix}person.fields.name`,
          `${prefix}content`,
          `${prefix}aiSummary`,
          `${prefix}description`,
          `${prefix}relatedKeywords`,
          `${prefix}allTopics.title`,
        ]
      : [];
    const isAIEnabled = yield select(isAIDescriptionEnabled);
    const restrictSearchableAttributes = isAIEnabled
      ? defaultSearchableAttributes
      : defaultSearchableAttributes.filter(key => key !== `${prefix}aiSummary`);
    const AlgoliaIndex = AlgoliaClient.initIndex(definitiveIndex);

    let optionalWords;
    if (finalQuery && finalQuery !== '') {
      optionalWords = finalQuery.replace(/,/g, '').split(' ');
    }

    let filterQuery = '';
    let wordCountQuery = '';
    let clientQuery = '';
    let requiredTagsQuery = '';
    let excludedTagsQuery = '';
    let stateQuery = '';
    let lengthQuery = '';
    let focusQuery = '';
    let audienceQuery = '';
    let costQuery = '';
    // let audienceOptionalQuery = [];
    let availableInQuery = '';
    let clientExclusiveQuery = '';
    let availableInOptionalQuery = [];
    if (!_isEmpty(allTopicFilters)) {
      filterQuery += 'AND (';
      _forEach(allTopicFilters, (value, index) => {
        filterQuery += `allTopics.slug:"${value}"`;
        if (index !== allTopicFilters.length - 1) {
          filterQuery += ' OR ';
        }
      });
      filterQuery += ')';
    }

    if (!hasClientShortName) {
      clientExclusiveQuery = ` AND NOT type:'Client Exclusive'`;
    }

    if (
      !_isEmpty(_get(clientInfo, 'metadata.hideCostDescriptionResources', []))
    ) {
      filterQuery += `AND ${_get(
        clientInfo,
        'metadata.hideCostDescriptionResources',
        [],
      )
        .map(item => `NOT costDescription:"${item}"`)
        .join(' AND ')} `;
    }

    if (!_isEmpty(costFilters)) {
      costQuery += 'AND (';
      _forEach(costFilters, (value, index) => {
        if (value === 'Free') {
          costQuery += 'mappedCost = 0 ';
        } else if (value === '$') {
          costQuery += 'mappedCost >= 1 ';
        }
        if (index !== costFilters.length - 1) {
          costQuery += ' OR ';
        }
      });
      costQuery += ') ';
      filterQuery += costQuery;
    }

    if (hasClientShortName) {
      clientQuery = ` AND ${getAlgoliaClientQuery(
        clientInfo,
        'client.shortName',
        'clientExclude.shortName',
      )}`;
      filterQuery += clientQuery;
    }

    const shouldFilterShortArticles = _get(
      clientInfo,
      'metadata.filterShortArticles',
      false,
    );
    if (shouldFilterShortArticles) {
      wordCountQuery = ` AND wordCount > ${_get(
        featuresConfig,
        'config.articlesMinimumWordCount',
        400,
      )}`;
    }

    let profileFilters = yield select(makeSelectProfileFilters());
    if (!profileFilters.isLoaded) {
      yield take(SET_PROFILE_FILTERS);
      profileFilters = yield select(makeSelectProfileFilters());
    }
    if (!_isEmpty(_get(profileFilters, 'insuranceTags'))) {
      requiredTagsQuery = ` AND (requiredTags:"none" OR ${profileFilters.insuranceTags
        .map(tag => `requiredTags:"${tag.id}"`)
        .join(' OR ')})`;
    } else {
      requiredTagsQuery = ' AND requiredTags:"none"';
    }
    filterQuery += requiredTagsQuery;

    if (!_isEmpty(_get(profileFilters, 'tags'))) {
      excludedTagsQuery = ` AND ${profileFilters.tags
        .map(tag => `NOT excludedTags:"${tag.id}"`)
        .join(' AND ')}`;
    }
    filterQuery += excludedTagsQuery;

    if (
      !_isEmpty(_get(profileFilters, 'state')) &&
      profileFilters.state !== 'all'
    ) {
      stateQuery = ` AND (state:"all" OR state:"${profileFilters.state}")`;
    }
    filterQuery += stateQuery;

    const clientAudienceQuery = getClientAudienceFilters(
      profileFilters,
      'audience.id',
    );
    if (clientAudienceQuery) filterQuery += clientAudienceQuery;

    if (!_isEmpty(durationFilters)) {
      lengthQuery += 'AND isPodcastSeries:false AND isMinutesSet:true AND (';
      _forEach(durationFilters, (value, index) => {
        if (value === 'Under 5 Minutes') {
          lengthQuery += 'minutes: 1 TO 5';
        } else if (value === '5-20 Minutes') {
          lengthQuery += 'minutes: 5 TO 20 ';
        } else if (value === 'More than 20 Minutes') {
          lengthQuery += 'minutes > 20 ';
        }
        if (index !== durationFilters.length - 1) {
          lengthQuery += ' OR ';
        }
      });
      lengthQuery += ') ';
    }

    if (!_isEmpty(focusFilters)) {
      focusQuery += 'AND (';
      _forEach(focusFilters, (value, index) => {
        focusQuery += `tags:"${value}"`;
        if (index !== focusFilters.length - 1) {
          focusQuery += ' OR ';
        }
      });
      focusQuery += ')';
    }

    if (!_isEmpty(availableInFilters)) {
      availableInQuery += 'AND (';
      _forEach(availableInFilters, (value, index) => {
        availableInQuery += `availableIn:"${value}"`;
        if (index !== availableInFilters.length - 1) {
          availableInQuery += ' OR ';
        }
      });
      availableInQuery += ')';
      availableInOptionalQuery = availableInFilters.map(
        value => `availableIn:${value}`,
      );
    }

    if (!_isEmpty(audienceFilters)) {
      audienceQuery = ` AND (${audienceFilters
        .map(item => `audience:"${item}"`)
        .join(' OR ')})`;
    }

    const maxScore = 3;
    const clientScore = maxScore;
    const clientGroupScore = maxScore - 1;
    const seriesScore = maxScore - 2;
    const optionalFilterQuery = [
      ...availableInOptionalQuery,
      `type:Series<score=${seriesScore}>`,
    ];
    if (prioritizeClientResources) {
      optionalFilterQuery.push(
        `clients:${clientInfo.shortName}<score=${clientScore}>`,
      );
      if (!_isEmpty(_get(clientInfo, 'clientGroup'))) {
        optionalFilterQuery.push(
          `clients:${_get(
            clientInfo,
            'clientGroup.shortName',
          )}<score=${clientGroupScore}>`,
        );
      }
    }

    const algoliaRatingQuery = yield call(getAlgoliaRatingQuery);
    const algoliaTopicsQuery = yield call(
      getAlgoliaTopicsQuery,
      'filterTopics',
    );
    const algoliaAssessmentsQuery = yield call(getAlgoliaAssessmentQuery);
    const excludeTopicsSlug = yield call(getTopicsSlugQuery);

    // Prepare Topics filter query
    let topicsTitleQuery = '';
    let excludeTopicsTitleQuery = '';
    if (!_isEmpty(allTopicFilters)) {
      topicsTitleQuery += 'AND (';
      _forEach(allTopicFilters, (value, index) => {
        topicsTitleQuery += `slug:"${value}" OR allTopics.slug:"${value}"`;
        if (index !== allTopicFilters.length - 1) {
          topicsTitleQuery += ' OR ';
        }
      });
      topicsTitleQuery += ')';
    }
    if (!_isEmpty(excludeTopicsSlug)) {
      topicsTitleQuery += ' AND ';
      excludeTopicsTitleQuery += ' AND ';
      _forEach(excludeTopicsSlug, (value, index) => {
        topicsTitleQuery += `NOT slug:"${value}"`;
        excludeTopicsTitleQuery += `NOT slug:"${value}"`;
        if (index !== excludeTopicsSlug.length - 1) {
          topicsTitleQuery += ' AND ';
          excludeTopicsTitleQuery += ' AND ';
        }
      });
    }

    const excludeResourceTypesFilters = _isEmpty(clientExcludedResourceTypes)
      ? ''
      : ` AND ${clientExcludedResourceTypes
          .map(type => `NOT expandedType:"${type}"`)
          .join(' AND ')}`;
    const returnedTypeFilters = yield all([
      call(() =>
        AlgoliaIndex.searchForFacetValues(`${prefix}type`, '', {
          maxFacetHits: 100,
          filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${lengthQuery} ${costQuery}`,
          restrictSearchableAttributes,
        }),
      ),
      call(() =>
        AlgoliaIndex.searchForFacetValues(`${prefix}type`, '', {
          query: finalQuery,
          maxFacetHits: 100,
          filters: `reviewStatus:'Accepted' AND type:'Topics' ${topicsTitleQuery} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${focusQuery} ${availableInQuery} ${audienceQuery} ${lengthQuery} ${algoliaAssessmentsQuery} ${clientQuery} ${requiredTagsQuery} ${stateQuery} ${wordCountQuery}`,
          ...(optionalWords ? { optionalWords } : {}),
          restrictSearchableAttributes,
        }),
      ),
      call(() =>
        AlgoliaIndex.searchForFacetValues(`${prefix}type`, '', {
          query: finalQuery,
          maxFacetHits: 100,
          filters: `reviewStatus:'Accepted' AND type:'Assessments' ${algoliaTopicsQuery} ${algoliaRatingQuery} ${focusQuery} ${availableInQuery} ${filterQuery} ${audienceQuery} ${algoliaAssessmentsQuery}${wordCountQuery}`,
          ...(optionalWords ? { optionalWords } : {}),
          restrictSearchableAttributes,
        }),
      ),
      call(() =>
        AlgoliaIndex.searchForFacetValues(`${prefix}type`, '', {
          query: finalQuery,
          maxFacetHits: 100,
          filters: `reviewStatus:'Accepted' AND (type:'Client Exclusive' OR type:'Links' OR type:'Services') ${algoliaTopicsQuery} ${algoliaRatingQuery} ${filterQuery} ${focusQuery} ${availableInQuery} ${audienceQuery} ${wordCountQuery}`,
          ...(optionalWords ? { optionalWords } : {}),
          restrictSearchableAttributes,
        }),
      ),
      call(() =>
        AlgoliaIndex.searchForFacetValues(`${prefix}type`, '', {
          query: finalQuery,
          maxFacetHits: 100,
          filters: `reviewStatus:'Accepted' AND type:'Links' ${algoliaTopicsQuery} ${algoliaRatingQuery} ${focusQuery} ${availableInQuery} ${filterQuery} ${audienceQuery} AND client.shortName:"none" ${wordCountQuery}`,
          ...(optionalWords ? { optionalWords } : {}),
          restrictSearchableAttributes,
        }),
      ),
      call(() =>
        AlgoliaIndex.searchForFacetValues(`${prefix}type`, '', {
          query: finalQuery,
          maxFacetHits: 100,
          filters: `reviewStatus:'Accepted' AND type:'Services' ${algoliaTopicsQuery} ${algoliaRatingQuery} ${focusQuery} ${availableInQuery} ${filterQuery} ${audienceQuery} AND client.shortName:"none" ${wordCountQuery}`,
          ...(optionalWords ? { optionalWords } : {}),
          restrictSearchableAttributes,
        }),
      ),
      call(() =>
        AlgoliaIndex.searchForFacetValues(`${prefix}type`, '', {
          query: finalQuery,
          maxFacetHits: 100,
          filters: `reviewStatus:'Accepted' AND NOT type:'Topics' AND NOT type:'Assessments' AND NOT displayOnly:true ${excludeResourceTypesFilters} ${focusQuery} ${filterQuery} ${audienceQuery} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${audienceQuery} ${availableInQuery} ${lengthQuery} ${wordCountQuery}`,
          ...(optionalWords ? { optionalWords } : {}),
          restrictSearchableAttributes,
        }),
      ),
    ]);
    const typeFilterOptions = returnedTypeFilters[0];
    const foundTypeFilters = returnedTypeFilters[6].facetHits.map(
      el => el.value,
    );
    typeFilterOptions.facetHits = returnedTypeFilters[0].facetHits
      .map(el => {
        if (resourceTypeReverseMapping[el.value] === 'Topics') {
          return {
            value: el.value,
            count: _get(returnedTypeFilters, '1.facetHits.0.count', 0),
          };
        }
        if (resourceTypeReverseMapping[el.value] === 'Assessments') {
          return {
            value: el.value,
            count: _get(returnedTypeFilters, '2.facetHits.0.count', 0),
          };
        }
        if (
          !_isEmpty(clientInfo) &&
          resourceTypeReverseMapping[el.value] === 'Client Exclusive'
        ) {
          return {
            value: el.value,
            label: clientInfo.formalShortName,
            count: _get(returnedTypeFilters, '3.facetHits').reduce(
              (res, next) => res + next.count,
              0,
            ),
          };
        }
        if (foundTypeFilters.includes(el.value)) {
          if (resourceTypeReverseMapping[el.value] === 'Links') {
            const data = {
              value: el.value,
              count: _get(returnedTypeFilters, '4.facetHits.0.count', 0),
            };

            return data;
          }
          if (resourceTypeReverseMapping[el.value] === 'Services') {
            const data = {
              value: el.value,
              count: _get(returnedTypeFilters, '5.facetHits.0.count', 0),
            };

            return data;
          }

          return {
            value: el.value,
            count: returnedTypeFilters[6].facetHits.find(
              filter => filter.value === el.value,
            ).count,
          };
        }

        return {
          value: el.value,
          count: 0,
        };
      })
      .filter(el => {
        if (resourceTypeReverseMapping[el.value] === 'Client Exclusive')
          return !_isEmpty(clientShortName) && clientShortName !== 'none';
        return true;
      });

    const finalTypeFilters = !_isEmpty(typeFilters)
      ? typeFilters
      : typeFilterOptions.facetHits.map(el => el.value);
    const filteredTypeFilters = finalTypeFilters.filter(
      el =>
        resourceTypeReverseMapping[el] !== 'Topics' &&
        resourceTypeReverseMapping[el] !== 'Assessments' &&
        resourceTypeReverseMapping[el] !== 'Client Exclusive' &&
        resourceTypeReverseMapping[el] !== 'Links' &&
        resourceTypeReverseMapping[el] !== 'Services',
    );
    let typeQuery = '';
    typeQuery =
      _isEmpty(typeFilters) || typeFilters.includes('Lists')
        ? `AND NOT displayOnly:true `
        : '';
    if (!_isEmpty(typeFilters)) {
      typeQuery += 'AND ';
      _forEach(typeFilters, (value, index) => {
        if (resourceTypeReverseMapping[value] === 'Client Exclusive') {
          typeQuery += `type:"Client Exclusive" OR type:"Links" OR type:"Services"`;
        } else {
          typeQuery += `type:"${resourceTypeReverseMapping[value]}"`;
        }
        if (index !== typeFilters.length - 1) {
          typeQuery += ' OR ';
        }
      });
    }

    const algoliaAllTopicsQuery = yield select(makeSelectAlgoliaTopicsQuery());

    const allTopicsFilterOptions = yield call(() =>
      AlgoliaIndex.searchForFacetValues(
        'allTopics.slug',
        algoliaAllTopicsQuery,
        {
          query: finalQuery,
          maxFacetHits: 50,
          filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${topicsTitleQuery} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${requiredTagsQuery} ${wordCountQuery}`,
          ...(optionalWords ? { optionalWords } : {}),
          restrictSearchableAttributes,
        },
      ),
    );

    const focusFilterOptions = yield call(() =>
      AlgoliaIndex.searchForFacetValues(`tags`, '', {
        query: finalQuery,
        maxFacetHits: 50,
        filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${filterQuery} ${topicsTitleQuery} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${wordCountQuery}`,
        ...(optionalWords ? { optionalWords } : {}),
        restrictSearchableAttributes,
      }),
    );

    const audienceFilterOptions = yield call(() =>
      AlgoliaIndex.searchForFacetValues(`audience`, '', {
        query: finalQuery,
        maxFacetHits: 100,
        filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${filterQuery} ${focusQuery} ${lengthQuery} ${topicsTitleQuery} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${availableInQuery} ${wordCountQuery}`,
        ...(optionalWords ? { optionalWords } : {}),
        restrictSearchableAttributes,
      }),
    );

    const availableFilterOptions = yield call(() =>
      AlgoliaIndex.searchForFacetValues('availableIn', '', {
        query: finalQuery,
        maxFacetHits: 50,
        filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${filterQuery} ${topicsTitleQuery} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${wordCountQuery}`,
        ...(optionalWords ? { optionalWords } : {}),
        restrictSearchableAttributes,
      }),
    );

    const finalAvailableInHits = parseAvailableInFilters({
      availableInFacetHits: availableFilterOptions.facetHits,
    });
    availableFilterOptions.facetHits = finalAvailableInHits;

    const lengthRequest = yield call(() =>
      AlgoliaClient.multipleQueries([
        {
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${focusQuery} ${filterQuery} AND isMinutesSet:true AND underFiveMinutes:true ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
            restrictSearchableAttributes,
          },
        },
        {
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${focusQuery} ${filterQuery} AND isMinutesSet:true AND (minutes: 5 TO 20) ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
            restrictSearchableAttributes,
          },
        },
        {
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${focusQuery} ${filterQuery} AND isMinutesSet:true AND minutes > 20 AND type:Series OR type:Podcasts OR type:Videos ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
            restrictSearchableAttributes,
          },
        },
      ]),
    );
    const costRequest = yield call(() =>
      AlgoliaClient.multipleQueries([
        {
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${focusQuery} ${filterQuery} ${lengthQuery} AND mappedCost=0 ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
            attributesToRetrieve: [],
            attributesToHighlight: [],
          },
        },
        {
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${typeQuery} ${focusQuery} ${filterQuery} ${lengthQuery} AND mappedCost >= 1 ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
            attributesToRetrieve: [],
            attributesToHighlight: [],
          },
        },
      ]),
    );

    const costFilterOptions = {
      facetHits: COSTS_ARRAY.map((item, idx) => ({
        ...item,
        count: costRequest.results[idx].nbHits,
        label: _get(resourcesSiteCopy, [
          'pageCopy',
          'filtersMapping',
          item.label,
        ]),
      })).filter(item => item.count),
    };
    const lengthFilterOptions = {
      facetHits: [
        {
          value: 'Under 5 Minutes',
          count: lengthRequest.results[0].nbHits,
          label: _get(resourcesSiteCopy, [
            'pageCopy',
            'filtersMapping',
            'Under 5 Minutes',
          ]),
        },
        {
          value: '5-20 Minutes',
          count: lengthRequest.results[1].nbHits,
          label: _get(resourcesSiteCopy, [
            'pageCopy',
            'filtersMapping',
            '5-20 Minutes',
          ]),
        },
        {
          value: 'More than 20 Minutes',
          count: lengthRequest.results[2].nbHits,
          label: _get(resourcesSiteCopy, [
            'pageCopy',
            'filtersMapping',
            'More than 20 Minutes',
          ]),
        },
      ].filter(item => item.count),
    };

    let hits = [];
    let nbPages = 0;
    let nbHits = 0;
    let processingTimeMS = 0;
    const separateHits = {};
    const separateHitsPerPage = isMobileEmbedded ? 4 : 12;

    if (presentation === 'mixed') {
      const finalOptionalFilters = isMobileEmbedded
        ? ['type:Topics<score=3>', ...optionalFilterQuery]
        : optionalFilterQuery;
      const response = yield call(() =>
        AlgoliaClient.multipleQueries([
          {
            indexName: definitiveIndex,
            query: finalQuery,
            params: {
              page,
              hitsPerPage,
              getRankingInfo: false,
              filters: `reviewStatus:'Accepted'${excludeResourceTypesFilters} ${clientExclusiveQuery} ${excludeTopicsTitleQuery} ${typeQuery} ${focusQuery} ${filterQuery} ${lengthQuery} ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${availableInQuery} ${algoliaAssessmentsQuery} ${wordCountQuery}`,
              optionalFilters: finalOptionalFilters,
              ...(optionalWords ? { optionalWords } : {}),
            },
          },
        ]),
      );
      [{ hits, nbPages, nbHits, processingTimeMS }] = response.results;
    } else if (presentation === 'separate') {
      const queries = [];
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Topics)) &&
        !clientExcludedResourceTypes.includes('Topics')
      )
        queries.push({
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            hitsPerPage: separateHitsPerPage,
            filters: `reviewStatus:'Accepted' AND type:'Topics'${excludeResourceTypesFilters} ${topicsTitleQuery} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${focusQuery} ${audienceQuery} ${availableInQuery} ${lengthQuery} ${costQuery} ${algoliaAssessmentsQuery} ${clientQuery} ${requiredTagsQuery} ${stateQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
          },
        });
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Assessments)) &&
        !clientExcludedResourceTypes.includes('Assessments')
      )
        queries.push({
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            hitsPerPage: separateHitsPerPage,
            filters: `reviewStatus:'Accepted' AND type:'Assessments'${excludeResourceTypesFilters} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${filterQuery} ${audienceQuery} ${lengthQuery} ${algoliaAssessmentsQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
          },
        });
      if (
        _isEmpty(typeFilters) ||
        typeFilters.includes(resourceTypeMapping['Client Exclusive'])
      )
        queries.push({
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            hitsPerPage: separateHitsPerPage,
            filters: `reviewStatus:'Accepted' AND (type:'Client Exclusive' OR type:'Links' OR type:'Services') ${excludeResourceTypesFilters} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${filterQuery} ${audienceQuery} ${lengthQuery} ${algoliaAssessmentsQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
          },
        });
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Links)) &&
        !clientExcludedResourceTypes.includes('Links')
      )
        queries.push({
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            hitsPerPage: separateHitsPerPage,
            filters: `reviewStatus:'Accepted' AND type:'Links' ${excludeResourceTypesFilters} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${filterQuery} ${audienceQuery} ${lengthQuery} AND client.shortName:"none" ${algoliaAssessmentsQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
          },
        });
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Services)) &&
        !clientExcludedResourceTypes.includes('Services')
      )
        queries.push({
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            hitsPerPage: separateHitsPerPage,
            filters: `reviewStatus:'Accepted' AND type:'Services' ${excludeResourceTypesFilters} ${algoliaTopicsQuery} ${algoliaRatingQuery} ${filterQuery} ${audienceQuery} ${lengthQuery} AND client.shortName:"none" ${algoliaAssessmentsQuery} ${availableInQuery} ${wordCountQuery}`,
            ...(optionalWords ? { optionalWords } : {}),
          },
        });
      queries.push(
        ...filteredTypeFilters.map(type => ({
          indexName: definitiveIndex,
          query: finalQuery,
          params: {
            removeWordsIfNoResults: 'none',
            hitsPerPage: separateHitsPerPage,
            getRankingInfo: false,
            filters: `reviewStatus:'Accepted' AND NOT displayOnly:true AND ${prefix}type:"${type}" ${focusQuery} ${filterQuery} ${lengthQuery} ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${availableInQuery} ${algoliaAssessmentsQuery} ${requiredTagsQuery} ${wordCountQuery} ${excludeResourceTypesFilters}`,
            optionalFilters: optionalFilterQuery,
            ...(optionalWords ? { optionalWords } : {}),
          },
        })),
      );
      const response = yield call(() => AlgoliaClient.multipleQueries(queries));

      let i = 0;
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Topics)) &&
        !clientExcludedResourceTypes.includes('Topics')
      ) {
        separateHits[resourceTypeMapping.Topics] = response.results[i].hits;
        i += 1;
      }
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Assessments)) &&
        !clientExcludedResourceTypes.includes('Assessments')
      ) {
        separateHits[resourceTypeMapping.Assessments] =
          response.results[i].hits;
        i += 1;
      }
      if (
        _isEmpty(typeFilters) ||
        typeFilters.includes(resourceTypeMapping['Client Exclusive'])
      ) {
        separateHits[resourceTypeMapping['Client Exclusive']] =
          response.results[i].hits;
        i += 1;
      }
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Links)) &&
        !clientExcludedResourceTypes.includes('Links')
      ) {
        separateHits[resourceTypeMapping.Links] = response.results[i].hits;
        i += 1;
      }
      if (
        (_isEmpty(typeFilters) ||
          typeFilters.includes(resourceTypeMapping.Services)) &&
        !clientExcludedResourceTypes.includes('Services')
      ) {
        separateHits[resourceTypeMapping.Services] = response.results[i].hits;
        i += 1;
      }
      response.results
        .slice(i, response.results.length)
        .forEach((el, index) => {
          separateHits[filteredTypeFilters[index]] = el.hits;
        });

      nbHits = typeFilterOptions.facetHits
        .filter(facet => {
          if (_isEmpty(typeFilters)) return true;

          return typeFilters.includes(facet.value);
        })
        .reduce((accumulator, value) => accumulator + value.count, 0);
    }

    const data = {
      hits,
      separateHits,
      nbHits,
      generalNbHits: nbHits,
      nbPages,
      processingTimeMS,
    };
    yield put(
      getAlgoliaResourcesResult({
        page,
        data,
        filters: {
          typeFilterOptions,
          allTopicsFilterOptions,
          focusFilterOptions,
          audienceFilterOptions,
          availableFilterOptions,
          lengthFilterOptions,
          costFilterOptions,
        },
      }),
    );
  } catch (error) {
    yield put(getAlgoliaResourcesResult({ error: true }));
  } finally {
    if (page === 0) yield put(algoliaProcessing(false));
    else yield put(algoliaNextPageProcessing(false));
  }
}

function* getTopicFacetsSaga({ payload }) {
  const { query, generalQuery, filters = '' } = payload;

  try {
    yield put(algoliaTopicsProcessing(true));

    const AlgoliaIndex = AlgoliaClient.initIndex(RESOURCES_AVG_RATED_INDEX);
    /**
     * Add rating filter cutoff if present on specific cobrand
     */
    const clientDetails = yield select(makeSelectClientDetails());

    /**
     * Exclude topic facets if present on specific cobrand
     */
    const clientExcludedTopic = _get(
      clientDetails,
      'excludeTopicCollection.items',
    );
    const excludedTopicSlug = !_isEmpty(clientExcludedTopic)
      ? clientExcludedTopic.map(topic => topic.slug)
      : [];
    let finalFilters = filters;
    if (!_isEmpty(excludedTopicSlug)) {
      _forEach(excludedTopicSlug, slug => {
        finalFilters += ` AND NOT allTopics.slug:"${slug}"`;
      });
    }

    const locale = yield getAlgoliaLocaleCodeGenerator();

    const finalFacetQuery =
      locale !== 'en-US' ? yield getTranslatedQuery(query, locale) : query;
    const finalGeneralQuery =
      locale !== 'en-US'
        ? yield getTranslatedQuery(generalQuery, locale)
        : generalQuery;

    const allTopicsFilterOptions = yield call(() =>
      AlgoliaIndex.searchForFacetValues('allTopics.slug', finalFacetQuery, {
        query: finalGeneralQuery,
        maxFacetHits: 50,
        filters: `reviewStatus:'Accepted' ${finalFilters}`,
      }),
    );
    yield put(getAlgoliaTopicFacetsResult(allTopicsFilterOptions));
  } catch (error) {
    yield put(
      getAlgoliaTopicFacetsResult({
        facetHits: [],
      }),
    );
  } finally {
    yield put(algoliaTopicsProcessing(false));
  }
}

function* saveResourceSaga({ payload }) {
  try {
    yield put(processing(true));
    yield put(storeItemForSignIn({}));
    let userID;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      // const {
      //   location: { pathname },
      // } = payload;
      yield put(storeItemForSignIn({ type: 'resource', payload }));
      yield put(saveResourceResult({ error: true }));
      yield put(setAuthModalState({ show: true, type: 'favorite' }));
      // yield put(push(`/login?redirect=${pathname}`));
    }
    if (userID != null) {
      const { save, resource, isList, topic } = payload;
      const userResourceDoc = yield getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(userID)
        .collection('saved_resources')
        .doc(resource.resourceID);
      if (save) {
        const profile = yield select(makeSelectProfile());
        const savedResources = yield select(makeSelectSavedResources());
        const siteCopy = yield select(makeSelectSiteCopy());
        const clientDetails = yield select(makeSelectClientDetails());

        const npsSiteCopy = _get(
          siteCopy.filter(el => el.slug === 'nps')[0],
          'pageCopy',
          {},
        );
        const npsClient = _get(clientDetails, 'metadata.nps', {});
        const finalNPS = update(npsSiteCopy, {
          $merge: npsClient,
        });

        const numSavedResources = savedResources.length;
        const lastNPS = _get(profile, 'npsDate', 0);
        const favoriteCount = _get(
          finalNPS,
          'config.favoriteCount',
          DEFAULT_CONFIG.favoriteCount,
        );
        const daysForRetake = _get(
          finalNPS,
          'config.daysForRetake',
          DEFAULT_CONFIG.daysForRetake,
        );

        const userResource = yield userResourceDoc.get();
        if (!userResource.exists) {
          if (isList && topic) {
            const listResource = {
              ...resource,
              topics: [topic],
              timestamp: Date.now(),
            };
            yield userResourceDoc.set(listResource);
            yield put(
              saveResourceResult({
                added: true,
                error: false,
              }),
            );
          } else {
            yield userResourceDoc.set({
              ...resource,
              timestamp: Date.now(),
            });
            yield put(
              saveResourceResult({
                added: true,
                error: false,
              }),
            );
          }
        } else if (isList && topic) {
          const data = userResource.data();
          const { topics = [] } = data;
          if (topics.map(el => el.slug).includes(topic.slug)) {
            yield put(
              saveResourceResult({
                added: false,
                error: false,
              }),
            );
          } else {
            yield userResourceDoc.update({
              topics: topics.concat(topic),
            });
            yield put(
              saveResourceResult({
                added: true,
                error: false,
              }),
            );
          }
        } else {
          yield put(
            saveResourceResult({
              added: false,
              error: false,
            }),
          );
        }

        if (
          !_get(profile, 'email', '').includes('@crediblemind.com') &&
          numSavedResources + 1 > favoriteCount &&
          Date.now() > lastNPS + daysForRetake * 24 * 60 * 60 * 1000
        ) {
          yield put(
            setNPSVisibility({
              visibility: true,
              reason: 'favorite',
            }),
          );
        }
      } else if (isList && topic) {
        const userResource = yield userResourceDoc.get();
        const data = userResource.data();
        const { topics } = data;
        if (topics.length > 1) {
          yield userResourceDoc.update({
            topics: topics.filter(el => el.slug !== topic.slug),
          });
          yield put(
            saveResourceResult({
              added: false,
              error: false,
            }),
          );
        } else {
          yield userResourceDoc.delete();
          yield put(
            saveResourceResult({
              added: false,
              error: false,
            }),
          );
        }
      } else {
        yield userResourceDoc.delete();
        yield put(
          saveResourceResult({
            added: false,
            error: false,
          }),
        );
      }
    }
  } catch (error) {
    yield put(saveResourceResult({ error: true }));
  } finally {
    yield put(processing(false));
    yield put(getSavedResources());
  }
}

// function* getResourceRatingSaga({ payload }) {
//   try {
//     yield put(processing(true));
//     const { itemId } = payload;
//     const userID = yield getFirebase().auth().currentUser.uid;
//     const userRatingDoc = yield getFirebase()
//       .firestore()
//       .collection('user_feedback_resources')
//       .doc(itemId)
//       .collection('reviews')
//       .doc(userID)
//       .get();
//     const rating = userRatingDoc.data();
//     yield put(getResourceRatingResult(rating));
//   } catch (error) {
//     yield put(getResourceRatingResult({}));
//   } finally {
//     yield put(processing(false));
//   }
// }

function* rateResourceSaga({ payload }) {
  try {
    yield put(processing(true));
    yield put(storeItemForSignIn({}));
    let userID;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      // const {
      //   location: { pathname },
      // } = payload;
      yield put(storeItemForSignIn({ type: 'rate', payload }));
      yield put(
        rateResourceResult({
          rated: false,
          error: true,
        }),
      );
      yield put(setAuthModalState({ show: true, type: 'rate' }));
      // yield put(push(`/login?redirect=${pathname}`));
    }
    if (userID != null) {
      const shouldPopulateReview = getPopulateReview();

      const profile = yield select(makeSelectProfile());
      if (_isEmpty(profile.displayName))
        throw new Error('User name is missing');

      const { resourceID, data } = payload;

      // Create real object to allow query on Admin tool
      if (shouldPopulateReview) {
        const resourceRef = yield getFirebase()
          .firestore()
          .collection('user_feedback_resources')
          .doc(resourceID);
        const resourceDoc = yield resourceRef.get();
        if (!resourceDoc.exists) yield resourceRef.set({ id: resourceID });
      }

      const userReviewRef = yield getFirebase()
        .firestore()
        .collection('user_feedback_resources')
        .doc(resourceID)
        .collection('reviews')
        .doc(userID);
      const userReviewDoc = yield userReviewRef.get();
      if (!userReviewDoc.exists) data.visible = true;
      yield userReviewRef.set(
        {
          person: profile.displayName,
          person_img: profile.avatarUrl || null,
          ...data,
        },
        {
          merge: true,
        },
      );
      const userReviewSnapshot = yield userReviewRef.get();
      const fullReview = yield userReviewSnapshot.data();
      yield put(
        getUserReviews({ itemId: resourceID, disableFetchStatus: true }),
      );
      yield put(
        rateResourceResult({
          review: fullReview,
          rated: true,
          error: false,
        }),
      );
    }
  } catch (error) {
    yield put(rateResourceResult({ error: true }));
  } finally {
    yield put(processing(false));
  }
}

function* getResourceReviewSaga({ payload }) {
  try {
    yield put(processing(true));
    const { itemId } = payload;
    const userID = yield getFirebase().auth().currentUser.uid;
    const userReviewDoc = yield getFirebase()
      .firestore()
      .collection('user_feedback_resources')
      .doc(itemId)
      .collection('reviews')
      .doc(userID)
      .get();
    const review = userReviewDoc.data();
    yield put(getResourceReviewResult(review));
  } catch (error) {
    yield put(getResourceReviewResult({}));
  } finally {
    yield put(processing(false));
  }
}

function* reviewResourceSaga({ payload }) {
  try {
    yield put(processing(true));
    yield put(storeItemForSignIn({}));
    let userID;
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (error) {
      // const {
      //   location: { pathname },
      // } = payload;
      yield put(storeItemForSignIn({ type: 'review', payload }));
      yield put(
        reviewResourceResult({
          error: true,
          errorMessage: 'resourceReviewError',
        }),
      );
      // yield put(push(`/login?redirect=${pathname}`));
    }
    if (userID != null) {
      const shouldPopulateReview = getPopulateReview();

      const { resourceID, data } = payload;

      // Create real object to allow query on Admin tool
      if (shouldPopulateReview) {
        const resourceRef = yield getFirebase()
          .firestore()
          .collection('user_feedback_resources')
          .doc(resourceID);
        const resourceDoc = yield resourceRef.get();
        if (!resourceDoc.exists) yield resourceRef.set({ id: resourceID });
      }

      const userReviewDoc = yield getFirebase()
        .firestore()
        .collection('user_feedback_resources')
        .doc(resourceID)
        .collection('reviews')
        .doc(userID);
      yield userReviewDoc.set(data, { merge: true });

      const remindMeDoc = yield getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(userID)
        .collection('remind_me')
        .doc(resourceID);
      const remindMe = yield remindMeDoc.get();
      if (remindMe.exists && remindMe.data().shouldSend)
        yield remindMeDoc.update({ shouldSend: false });

      const userReviewSnapshot = yield userReviewDoc.get();
      const fullReview = yield userReviewSnapshot.data();
      yield put(
        reviewResourceResult({
          review: fullReview,
          rated: true,
          error: false,
        }),
      );
      yield put(
        getUserReviews({ itemId: resourceID, disableFetchStatus: true }),
      );
    }
  } catch (error) {
    yield put(
      reviewResourceResult({ error: true, errorMessage: error.message }),
    );
  } finally {
    yield put(processing(false));
  }
}

function* voteHelpfulResourceSaga({ payload }) {
  const { resourceID, isThumbUp, data = {}, name, type } = payload;
  const collectionName = getFeedbackDBCollectionNameByType(type);
  try {
    yield put(processing(true));
    yield put(submittingFeedback(true));
    let userID;
    let authID;
    try {
      authID = yield getFirebase().auth().currentUser.uid;
      userID = authID;
    } catch (e) {
      const sessionAnonymousId = yield select(makeSelectAnonymousId());
      userID = yield getLocalData('userIdTemp') || sessionAnonymousId;
    }
    const brand = getLocalData('brand') || 'none';
    const userFeedbackDoc = yield getFirebase()
      .firestore()
      .collection(collectionName)
      .doc(resourceID);
    yield getFirebase()
      .firestore()
      .runTransaction(transaction =>
        transaction.get(userFeedbackDoc).then(doc => {
          if (doc.exists) {
            const ratings = _clone(doc.data());

            ratings.name = name;
            ratings.type = type;

            const ratingsUpIds = ratings.up.users
              ? ratings.up.users.map(el => el.id)
              : [];
            const ratingsDownIds = ratings.down.users
              ? ratings.down.users.map(el => el.id)
              : [];
            const prevVoteUp = ratingsUpIds.includes(userID);
            const prevVoteDown = ratingsDownIds.includes(userID);

            if (isThumbUp) {
              if (!prevVoteUp) {
                if (ratings.up.users)
                  ratings.up.users.push({
                    id: userID,
                    brand,
                    timestamp: Date.now(),
                    ...data,
                  });
                else
                  ratings.up.users = [
                    { id: userID, brand, timestamp: Date.now(), ...data },
                  ];
                ratings.up.count += 1;
              } else {
                ratings.up.users = ratings.up.users.filter(
                  el => el.id !== userID,
                );
                ratings.up.users.push({
                  id: userID,
                  brand,
                  timestamp: Date.now(),
                  ...data,
                });
              }

              if (prevVoteDown) {
                ratings.down.users = ratings.down.users.filter(
                  el => el.id !== userID,
                );
                ratings.down.count -= 1;
              }
            } else {
              if (!prevVoteDown) {
                if (ratings.down.users)
                  ratings.down.users.push({
                    id: userID,
                    brand,
                    timestamp: Date.now(),
                    ...data,
                  });
                else
                  ratings.down.users = [
                    { id: userID, brand, timestamp: Date.now(), ...data },
                  ];
                ratings.down.count += 1;
              } else {
                ratings.down.users = ratings.down.users.filter(
                  el => el.id !== userID,
                );
                ratings.down.users.push({
                  id: userID,
                  brand,
                  timestamp: Date.now(),
                  ...data,
                });
              }

              if (prevVoteUp) {
                ratings.up.users = ratings.up.users.filter(
                  el => el.id !== userID,
                );
                ratings.up.count -= 1;
              }
            }
            transaction.set(userFeedbackDoc, ratings);
          } else {
            const users = [
              { id: userID, brand, timestamp: Date.now(), ...data },
            ];
            const feedbackData = {
              name,
              type,
              up: {
                count: isThumbUp ? 1 : 0,
                users: isThumbUp ? users : [],
              },
              down: {
                count: !isThumbUp ? 1 : 0,
                users: !isThumbUp ? users : [],
              },
            };

            transaction.set(userFeedbackDoc, feedbackData);
          }
        }),
      );

    if (authID) {
      const remindMeDoc = yield getFirebase()
        .firestore()
        .collection('user_resources')
        .doc(userID)
        .collection('remind_me')
        .doc(resourceID);
      const remindMe = yield remindMeDoc.get();
      if (remindMe.exists && remindMe.data().shouldSend)
        yield remindMeDoc.update({ shouldSend: false });
    }

    yield put(voteHelpfulResourceResult({ error: false }));
  } catch (error) {
    yield put(voteHelpfulResourceResult({ error: true }));
    yield put(processing(false));
  } finally {
    yield put(getHelpfulRatings({ resourceID, type }));
    yield put(submittingFeedback(false));
  }
}

function* getHelpfulRatingsSaga({ payload }) {
  try {
    yield put(processing(true));
    const { resourceID, type = '' } = payload;

    let userID;
    const collectionName = getFeedbackDBCollectionNameByType(type);
    try {
      userID = yield getFirebase().auth().currentUser.uid;
    } catch (e) {
      const sessionAnonymousId = yield select(makeSelectAnonymousId());
      userID = yield getLocalData('userIdTemp') || sessionAnonymousId;
    }
    const userFeedbackDoc = yield getFirebase()
      .firestore()
      .collection(collectionName)
      .doc(resourceID)
      .get();
    if (userFeedbackDoc.exists) {
      const ratings = userFeedbackDoc.data();
      const ratingsUpIds = ratings.up.users
        ? ratings.up.users.map(el => el.id)
        : [];
      const ratingsDownIds = ratings.down.users
        ? ratings.down.users.map(el => el.id)
        : [];
      const prevVoteUp = ratingsUpIds.includes(userID);
      const prevVoteDown = ratingsDownIds.includes(userID);

      let prevRating;
      if (prevVoteUp) prevRating = true;
      if (prevVoteDown) prevRating = false;
      yield put(
        getHelpfulRatingsResult({
          error: false,
          up: ratings.up.count,
          down: ratings.down.count,
          prevRating,
        }),
      );
    } else {
      yield put(
        getHelpfulRatingsResult({
          error: false,
          up: 0,
          down: 0,
          prevRating: null,
        }),
      );
    }
  } catch (error) {
    yield put(getHelpfulRatingsResult({ error: true }));
  } finally {
    yield put(processing(false));
  }
}

function* getRecommendedResources({ payload }) {
  try {
    if (isBot()) throw new Error('Do not call Algolia for Bot requests');

    const { topics, limit = 27, audience, type, slug } = payload;

    const locale = yield getAlgoliaLocaleCodeGenerator();
    const prefix = locale !== 'en-US' ? `${locale}_` : '';

    const profileFilters = yield select(makeSelectProfileFilters());
    let requiredTagsQuery = ' AND requiredTags:"none"';
    if (!_isEmpty(_get(profileFilters, 'insuranceTags'))) {
      requiredTagsQuery = ` AND (${profileFilters.insuranceTags
        .map(tag => `requiredTags:"${tag.id}"`)
        .join(' OR ')})`;
    }

    const siteConfig = yield select(makeSelectSiteConfig());
    const [featuresConfig] = siteConfig.filter(
      item => item.title === 'Features',
    );
    const clientInfo = yield select(makeSelectClientDetails());
    const clientExcludedResourceTypes =
      _get(clientInfo, 'excludeResourceTypes') || [];
    const resourcesWeightings = _get(
      clientInfo,
      'metadata.algoliaResourcesWeight',
    );
    const clientFilters = `AND ${getAlgoliaClientQuery(clientInfo)}`;

    const resourcesIndexName = getResourcesIndex(resourcesWeightings);

    const clientTopics = yield call(getTopicsSlugQuery);
    const algoliaRatingQuery = yield call(getAlgoliaRatingQuery);
    const algoliaTopicsQuery = yield call(
      getAlgoliaTopicsQuery,
      'filterTopics',
    );
    const algoliaTopicsNewsQuery = yield call(
      getAlgoliaTopicsQuery,
      `${prefix}topics.title`,
    );

    const definitiveTopics = topics.filter(el => !clientTopics.includes(el));

    const audienceTagsRelations = yield select(
      makeSelectAudienceTagsRelations(),
    );

    let filterQuery = '';
    let newsFilterQuery = '';
    let wordCountQuery = '';
    const shouldFilterShortArticles = _get(
      clientInfo,
      'metadata.filterShortArticles',
      false,
    );
    if (shouldFilterShortArticles) {
      wordCountQuery = `AND wordCount > ${_get(
        featuresConfig,
        'config.articlesMinimumWordCount',
        400,
      )}`;
    }
    if (!_isEmpty(definitiveTopics)) {
      filterQuery = ' AND ';
      _forEach(definitiveTopics, (value, index) => {
        filterQuery += `allTopics.slug:"${value}"`;
        newsFilterQuery += `${prefix}topics.title:"${value}"`;
        if (index !== definitiveTopics.length - 1) {
          filterQuery += ' OR ';
          newsFilterQuery += ' OR ';
        }
      });
    }
    // TODO: Expand optionalFilterQuery when multiple filters are available
    let audienceQuery = '';
    let audienceOptionalQuery;
    if (!_isEmpty(audience)) {
      audienceOptionalQuery = audience.map(value => `audience:${value}`);

      const excludedAudiences = _flatten(
        audience
          .map(item =>
            _get(audienceTagsRelations[item], '0.exclude', []).map(
              exclude => exclude.name,
            ),
          )
          .filter(item => !_isEmpty(item)),
      ).filter(item => !audience.includes(item));

      if (!_isEmpty(excludedAudiences)) {
        audienceQuery = ' AND ';
        _forEach(excludedAudiences, (value, index) => {
          audienceQuery += `NOT audience:"${value}"`;
          if (index !== excludedAudiences.length - 1) {
            audienceQuery += ' AND ';
          }
        });
      }
    }

    if (
      clientExcludedResourceTypes.includes('News') &&
      clientExcludedResourceTypes.includes('Assessments') &&
      clientExcludedResourceTypes.includes(type)
    ) {
      yield put(
        getRecommendedResourcesResult({
          hits: [],
          news: [],
        }),
      );
    }

    const response = yield call(() =>
      AlgoliaClient.multipleQueries([
        {
          indexName: resourcesIndexName,
          query: '',
          params: {
            hitsPerPage: limit,
            getRankingInfo: false,
            filters: `reviewStatus:'Accepted' AND type:'Assessments' ${filterQuery} ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${clientFilters} ${requiredTagsQuery}`,
            optionalFilters: audienceOptionalQuery,
          },
        },
        {
          indexName: resourcesIndexName,
          query: '',
          params: {
            hitsPerPage: limit,
            getRankingInfo: false,
            filters: `reviewStatus:'Accepted' AND slug:'whats-your-mental-health-profile'`,
          },
        },
        {
          indexName: resourcesIndexName,
          query: '',
          params: {
            hitsPerPage: limit,
            getRankingInfo: false,
            filters: `reviewStatus:'Accepted' AND type:"${type}" AND NOT slug:"${slug}" ${filterQuery} ${algoliaRatingQuery} ${algoliaTopicsQuery} ${audienceQuery} ${clientFilters} ${requiredTagsQuery} ${wordCountQuery}`,
            optionalFilters: audienceOptionalQuery,
          },
        },
        {
          indexName: NEWS_INDEX,
          query: '',
          params: {
            hitsPerPage: limit,
            getRankingInfo: false,
            filters: `NOT slug:"${slug}" ${
              newsFilterQuery ? `AND ${newsFilterQuery}` : ''
            } ${algoliaTopicsNewsQuery}`,
            optionalFilters: audienceOptionalQuery,
          },
        },
      ]),
    );
    const sortedResources = sortByTopicsRelevance(
      _get(response, 'results.2.hits', []),
      topics,
    );
    const hits = [];
    if (_get(response, 'results.0.hits', []).length > 0) {
      hits.push(..._get(response, 'results.0.hits', []));
      hits.push(
        ...sortedResources.slice(
          0,
          12 - _get(response, 'results.0.hits', []).length,
        ),
      );
    } else {
      hits.push(..._get(response, 'results.1.hits'));
      if (hits.length > 0) hits.push(...sortedResources.slice(0, 11));
      else hits.push(...sortedResources.slice(0, 12));
    }
    yield put(
      getRecommendedResourcesResult({
        hits,
        news: _get(response, 'results.3.hits', []).map(el => ({
          ...el,
          type: 'News',
        })),
      }),
    );
  } catch (error) {
    yield put(
      getRecommendedResourcesResult({
        hits: [],
        news: [],
      }),
    );
  }
}

function getAndAssembleData(review) {
  if (_has(review, 'visible') && !review.visible) return null;

  const dateObj = new Date(review.datetime);
  const data = {
    date: dateObj,
    body: review.review,
    person: review.person,
    person_img: review.person_img,
    type: 'cm',
    rating: review.rating,
  };
  return data;
}

function* getUserReviewsSaga({ payload }) {
  const clientDetails = yield select(state => state.main.clientDetails);
  if (_get(clientDetails, 'metadata.shouldHideReviews', false)) {
    return;
  }

  const { itemId, disableFetchStatus = false } = payload;
  try {
    if (!disableFetchStatus) {
      yield put(fetchingUserReviews(true));
    }
    const userFeedbacksDoc = yield getFirebase()
      .firestore()
      .collection('user_feedback_resources')
      .doc(itemId);
    const userReviewsSnapshot = yield userFeedbacksDoc
      .collection('reviews')
      .get();
    let userReviews = {};
    userReviewsSnapshot.forEach(review => {
      userReviews = { ...userReviews, [review.id]: review.data() };
    });
    const finalUserReviews = userReviews
      ? Object.keys(userReviews).map(
          key =>
            userReviews[key].visible !== false &&
            getAndAssembleData(userReviews[key]),
        )
      : [];
    yield put(getUserReviewsSuccess(finalUserReviews.filter(Boolean)));
  } finally {
    if (!disableFetchStatus) {
      yield put(fetchingUserReviews(false));
    }
  }
}

function* getUserHistorySaga() {
  try {
    yield put(processing(true));
    const userID = yield getFirebase().auth().currentUser.uid;
    const interactionDocs = yield getFirebase()
      .firestore()
      .collection('user_interaction')
      .where('userId', '==', userID)
      .where('type', 'in', ['resource-page-view', 'topic-view'])
      .get();
    const interactions = _uniqBy(
      interactionDocs.docs.map(doc => doc.data()),
      'resourceID',
    );

    yield put(getUserHistoryResult(interactions));
  } catch (error) {
    yield put(getUserHistoryResult([]));
  } finally {
    yield put(processing(false));
  }
}

// Individual exports for testing
export default function* defaultSaga() {
  yield takeLatest(GET_SAVED_RESOURCES, getSavedResourcesSaga);
  yield takeLatest(GET_ALGOLIA_RESOURCES, getAlgoliaResourcesSaga);
  yield takeLatest(GET_ALGOLIA_TOPIC_FACETS, getTopicFacetsSaga);
  yield takeLatest(GET_RESOURCE_REVIEW, getResourceReviewSaga);
  yield takeLatest(SAVE_RESOURCE, saveResourceSaga);
  yield takeLatest(RATE_RESOURCE, rateResourceSaga);
  yield takeLatest(REVIEW_RESOURCE, reviewResourceSaga);
  yield takeLatest(VOTE_HELPFUL_RESOURCE, voteHelpfulResourceSaga);
  yield takeLatest(GET_HELPFUL_RATINGS, getHelpfulRatingsSaga);
  yield takeLatest(GET_RECOMMENDED_RESOURCES, getRecommendedResources);
  yield takeLatest(GET_USER_REVIEWS, getUserReviewsSaga);
  yield takeLatest(GET_USER_HISTORY, getUserHistorySaga);
}
