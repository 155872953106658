import { gql } from '@apollo/client';
import {
  AppsFragment,
  ActivityFragment,
  AssessmentFragment,
  BookFragment,
  ClientFragment,
  ClientGroupFragment,
  ClientLandingFragment,
  ProgramFragment,
  OrganizationFragment,
  PersonFragment,
  PodcastFragment,
  SourceFragment,
  TopicFragment,
  VideoFragment,
  LinkFragment,
  ListFragment,
  SeriesFragment,
  CourseFragment,
} from './fragments';
export default isPreviewMode => gql`
  ${ClientFragment}
  ${ClientGroupFragment}
  ${AppsFragment}
  ${ActivityFragment}
  ${AssessmentFragment}
  ${BookFragment}
  ${ClientLandingFragment}
  ${ProgramFragment}
  ${OrganizationFragment}
  ${PersonFragment}
  ${PodcastFragment}
  ${SourceFragment}
  ${TopicFragment}
  ${VideoFragment}
  ${LinkFragment}
  ${ListFragment}
  ${SeriesFragment}
  ${CourseFragment}
  query Landing($slug: String!, $locale: String!) {
    landingCollection(where: { slug: $slug }, limit: 1, locale: $locale, preview:${isPreviewMode}) {
      items {
        sys {
          id
        }
        title
        slug
        introTitle
        pageText
        optionalSections
        listSortLogic
        heroStyle
        heroGraphics
        primaryColor
        fontColor
        introVideo {
          url
        }
        userTerm
        client {
          slug
          name
          logoReverse {
            url
          }
          shortName
          metadata
          __typename
        }
        featuredResourcesCollection {
          items {
            ...ApplicationFields
            ...ActivityFields
            ...AssessmentFields
            ...BookFields
            ...ClientLandingFields
            ...ProgramFields
            ...OrganizationFields
            ...PersonFields
            ...PodcastFields
            ...SourceFields
            ...TopicsFields
            ...VideoFields
            ...ListFields
            ...LinkFields
            ...SeriesFields
            ...CoursesFields
          }
        }
        featuredCollageCollection {
          items {
            ...ApplicationFields
            ...ActivityFields
            ...AssessmentFields
            ...BookFields
            ...ClientLandingFields
            ...ProgramFields
            ...OrganizationFields
            ...PersonFields
            ...PodcastFields
            ...SourceFields
            ...TopicsFields
            ...VideoFields
          }
        }
        siteImagesCollection {
          items {
            title
            homePage
            splashLarge {
              url
            }
            splashMedium {
              url
            }
            splashSmall {
              url
            }
            fontColor
            altText
            isTransparent
          }
        }
        audienceTypeCollection {
          items {
            sys {
              id
            }
            name
            reviewStatus
          }
        }
        intro {
          json
        }
        heroCta {
          json
        }
        heroFooter {
          json
        }
        introMedia {
          url
        }
        topTopicsCollection {
          items {
            title
            slug
            reviewStatus
            type
            description
            sys {
              id
            }
            image {
              url
            }
          }
        }
        clientResourcesCollection {
          items {
            ...SourceFields
            ...ActivityFields
            ...LinkFields
          }
        }
        listsCollection {
          items {
            sys {
              id
            }
          }
        }
      }
    }
  }
`;
