import _get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { put, select, take, takeLatest } from 'redux-saga/effects';
import contentfulClient from 'utils/contentfulService';
import { getContentfulLocaleFilter } from 'containers/Main/utils';
import { getIsPracticesEnabled } from 'containers/Main/selectors';
import {
  GET_CLIENT_DETAILS_SUCCESS,
  GET_SITE_CONFIG_SUCCESS,
} from 'containers/Main/constants';
import { GET_TAKEN_PRACTICES } from './constants';
import { getTakenPracticesSuccess } from './actions';

function* getTakenPracticesSaga() {
  const siteConfig = yield select(state => state.main.siteConfig);
  if (!siteConfig.length) {
    yield take(GET_SITE_CONFIG_SUCCESS);
  }

  const isPracticesEnabled = yield select(getIsPracticesEnabled);
  if (!isPracticesEnabled) {
    return;
  }

  let clientDetails = yield select(state => state.main.clientDetails);
  if (isEmpty(clientDetails)) {
    yield take(GET_CLIENT_DETAILS_SUCCESS);
    clientDetails = yield select(state => state.main.clientDetails);
  }
  const excludeResourceTypes =
    _get(clientDetails, 'excludeResourceTypes') || [];
  const localeFilters = yield getContentfulLocaleFilter();

  const requestPayload = {
    content_type: 'practice',
    'fields.reviewStatus[in]': 'Accepted',
    'fields.courseContent': false,
    limit: 1000,
    include: 1,
    ...localeFilters,
  };

  const [clientPractices, restPractices] = yield Promise.all([
    contentfulClient.getEntries({
      ...requestPayload,
      'fields.clientInclude.sys.id[in]': clientDetails.sys.id,
    }),
    excludeResourceTypes.includes('Practices')
      ? Promise.resolve([])
      : contentfulClient.getEntries({
          ...requestPayload,
          'fields.clientInclude[exists]': false,
        }),
  ]);
  const takenPractices = {};
  const allPractices = [...clientPractices.items, ...restPractices.items];

  yield put(getTakenPracticesSuccess({ takenPractices, allPractices }));
}

export default function* defaultSaga() {
  yield takeLatest(GET_TAKEN_PRACTICES, getTakenPracticesSaga);
}
