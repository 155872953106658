/* eslint-disable no-duplicate-case */
import _get from 'lodash/get';
import _lowerFirst from 'lodash/lowerFirst';

export const getFormattedType = type => {
  switch (type) {
    case 'Videos':
    case 'video':
      return 'video';
    case 'Topics':
      return 'topic';
    case 'Links':
    case 'link':
    case 'Client Exclusive':
      return 'link';
    case 'Apps':
    case 'application':
      return 'app';
    case 'Podcasts':
    case 'podcast':
    case 'episode':
    case 'Episode':
      return 'podcast';
    case 'People':
    case 'person':
      return 'person';
    case 'Books':
    case 'book':
      return 'book';
    case 'Articles':
    case 'Blogs':
    case 'Insights':
    case 'activity':
    case 'News':
      return 'article';
    case 'Organizations':
    case 'organization':
      return 'organization';
    case 'Events':
    case 'events':
      return 'event';
    case 'list':
      return 'list';
    case 'Online Programs':
    case 'onlineProgram':
    case 'onlineprogram':
      return 'program';
    case 'topics':
    case 'Topics':
      return 'topic';
    case 'Assessments':
    case 'assessments':
      return 'assessment';
    case 'landing':
      return 'landing';
    case 'source':
      return 'source';
    case 'Services':
    case 'services':
      return 'services';
    case 'Series':
    case 'series':
      return 'series';
    case 'Courses':
    case 'courses':
      return 'course';
    case 'practice':
      return 'practice';
    default:
      return null;
  }
};

export const getTokenFriendlyType = type => {
  switch (type) {
    case 'Videos':
    case 'video':
      return 'video';
    case 'Topics':
      return 'topic';
    case 'Links':
    case 'link':
      return 'link';
    case 'Apps':
    case 'application':
      return 'app';
    case 'Podcasts':
    case 'podcast':
    case 'episode':
    case 'Episode':
      return 'podcast';
    case 'People':
    case 'person':
      return 'person';
    case 'Books':
    case 'book':
      return 'book';
    case 'Articles':
    case 'Blogs':
    case 'Insights':
    case 'activity':
    case 'News':
      return 'article';
    case 'Organizations':
    case 'organization':
      return 'organization';
    case 'Events':
    case 'events':
      return 'event';
    case 'list':
      return 'list';
    case 'Online Programs':
    case 'onlineProgram':
    case 'onlineprogram':
      return 'program';
    case 'topics':
      return 'topic';
    case 'assessments':
      return 'assessment';
    case 'source':
      return 'FAQ';
    case 'Services':
    case 'services':
      return 'service';
    default:
      return null;
  }
};

export const getReverseType = type => {
  switch (type) {
    case 'activity':
      return 'Articles';
    case 'application':
      return 'Apps';
    case 'assessments':
      return 'Assessments';
    case 'book':
      return 'Books';
    case 'onlineProgram':
      return 'Online Programs';
    case 'organization':
      return 'Organizations';
    case 'person':
      return 'People';
    case 'podcast':
      return 'Podcasts';
    case 'source':
      return 'FAQ';
    case 'topics':
      return 'Topics';
    case 'video':
      return 'Videos';
    default:
      return null;
  }
};

export const getTypeUrl = type => {
  switch (type) {
    case 'Videos':
    case 'video':
      return ['videos'];
    case 'Topics':
    case 'topic':
      return ['topics'];
    case 'Apps':
    case 'application':
      return ['apps'];
    case 'Podcasts':
    case 'podcast':
    case 'episode':
    case 'Episode':
      return ['podcasts'];
    case 'People':
    case 'person':
      return ['people'];
    case 'Books':
    case 'book':
      return ['books'];
    case 'Articles':
      return ['articles'];
    case 'Blogs':
    case 'Insights':
      return ['blogs', 'insights'];
    case 'News':
      return ['news'];
    case 'activity':
      return ['articles', 'blogs', 'insights', 'news'];
    case 'Organizations':
    case 'organization':
      return ['organizations'];
    case 'Online Programs':
    case 'onlineProgram':
    case 'onlineprogram':
      return ['programs'];
    case 'Assessments':
    case 'assessments':
      return ['assessments'];
    case 'FAQ':
    case 'source':
      return ['faqs'];
    case 'Lists':
      return ['lists'];
    case 'Series':
      return ['series'];
    case 'Courses':
      return ['courses'];
    default:
      return [];
  }
};

export const getFinalType = (
  item,
  isWhatHelps,
  fromContentful,
  clientExclusiveAsResource,
) => {
  if (isWhatHelps) {
    return 'Topics';
  }

  if (fromContentful) {
    if (
      (_get(item, 'sys.contentType.sys.id') === 'source' ||
        _get(item, '__typename') === 'Source') &&
      _get(item, 'fields.type') === 'Client Exclusive'
    )
      return 'Client Exclusive';

    // means comes from gql request and have no sys.contentType.sys.id
    if (_get(item, '__typename')) {
      return _get(item, '__typename');
    }

    return item.sys.contentType.sys.id;
  }

  if (item.type === 'Client Exclusive' && clientExclusiveAsResource) {
    return item.expandedType;
  }
  if (!fromContentful && item.type === 'Articles' && _get(item, 'blog')) {
    return 'Insights';
  }

  return item.type;
};

export const getClientExcludedResourceTypesMapping = type => {
  switch (type) {
    case 'News':
      return 'news';
    case 'Articles':
      return 'activity';
    case 'Insights':
      return 'insights';
    case 'Online Programs':
      return 'onlineProgram';
    case 'Organizations':
      return 'organization';
    case 'People':
      return 'person';
    case 'Books':
      return 'book';
    case 'Assessments':
      return 'assessments';
    case 'Links':
      return 'link';
    case 'Podcasts':
      return 'podcast';
    case 'Services':
      return 'services';
    case 'Topics':
      return 'topics';
    case 'Apps':
      return 'application';
    case 'Videos':
      return 'video';
    case 'Series':
      return 'series';
    case 'Courses':
      return 'courses';
    default:
      return null;
  }
};

export const getResourceTypeFromGraphQLType = type => {
  if (!type) return type;

  return _lowerFirst(type);
};
