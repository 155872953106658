import { handleActions } from 'redux-actions';
import update from 'immutability-helper';
import { getTakenPracticesSuccess } from './actions';

const initialState = {
  allPractices: [],
  takenPractices: {},
};

const practicesReducer = handleActions(
  {
    [getTakenPracticesSuccess]: (state, action) =>
      update(state, {
        allPractices: { $set: action.payload.allPractices },
        takenPractices: { $set: action.payload.takenPractices },
      }),
  },
  initialState,
);

export default practicesReducer;
