import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import client from 'utils/contentfulService';
import { filterBasedOnInsuranceTags } from 'containers/TopicCenter/services/getTopicLinkResources';

const getLinks = async (
  key,
  {
    clientId,
    clientGroupId,
    ctfLocaleFilter,
    profileTags,
    insuranceTags,
    insuranceOptions,
    show,
  },
) => {
  const clientIds = [clientId];
  if (clientGroupId) clientIds.push(clientGroupId);

  const excludedTagsFilter = {};
  if (!_isEmpty(profileTags)) {
    excludedTagsFilter['fields.excludedTags.sys.id[nin]'] = profileTags
      .map(tag => tag.id)
      .join(',');
  }

  try {
    const ctfPayload = {
      content_type: 'link',
      'fields.client.sys.id[in]': clientIds.join(','),
      'fields.reviewStatus': 'Accepted',
      ...excludedTagsFilter,
      ...ctfLocaleFilter,
    };

    if (show) {
      ctfPayload['fields.show[in]'] = show;
    }

    const response = await client.getEntries(ctfPayload);

    const filteredItems = filterBasedOnInsuranceTags(
      _get(response, 'items', []),
      insuranceTags,
      insuranceOptions,
    );

    const prettyData = filteredItems.map(item => ({
      callToAction: _get(item, 'fields.callToAction'),
      image: _get(item, 'fields.image'),
      url: _get(item, 'fields.url'),
      slug: _get(item, 'fields.slug'),
      reviewStatus: _get(item, 'fields.reviewStatus'),
      shortDescription: _get(item, 'fields.subtitle'),
      sys: _get(item, 'sys'),
      name: _get(item, 'fields.title'),
      homeOrder: _get(item, 'fields.homeOrder'),
      resourceOrder: _get(item, 'fields.resourceOrder'),
      exitDisclaimer: _get(item, 'fields.exitDisclaimer'),
      highlights: _get(item, 'fields.highlights'),
      contactInfo: _get(item, 'fields.contactInfo'),
      contactInfoType: _get(item, 'fields.contactInfoType'),
      state: _get(item, 'fields.state'),
      show: _get(item, 'fields.show'),
      disclaimerText: _get(item, 'fields.disclaimerText'),
      visibility: _get(
        item,
        'fields.assessmentResultsVisibility.user-resources',
      ),
      topics: _get(item, 'fields.topics'),
      themes: _get(item, 'fields.themes'),
      context: _get(item, 'fields.context'),
    }));
    return prettyData;
  } catch (error) {
    throw new Error(error);
  }
};

export default getLinks;
