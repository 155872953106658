import { createSelector } from 'reselect';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';

import { filterBySlug, filterByTitle } from 'utils/objectUtils';
import { WITH_PUBLIC_MODE_CLIENTS } from '../../components/Hooks/useCheckModeParam';
import { isClientFeatureAllowed } from './utils';

const selectMainDomain = state => state.main;

const makeSelectProcessing = () =>
  createSelector(
    selectMainDomain,
    substate => substate.processing,
  );

const makeSelectClientDetails = () =>
  createSelector(
    selectMainDomain,
    substate => substate.clientDetails,
  );

const makeSelectClientDetailsLoaded = () =>
  createSelector(
    selectMainDomain,
    substate => substate.clientDetailsLoaded,
  );

const makeSelectAllowSignIn = () =>
  createSelector(
    selectMainDomain,
    getModeConfig,
    (main, modeConfig) => {
      if (!_get(main, 'firestoreStatus')) return false;

      const doesClientSupportPublicMode = WITH_PUBLIC_MODE_CLIENTS.includes(
        _get(main, 'clientDetails.shortName'),
      );
      if (
        doesClientSupportPublicMode &&
        !_get(modeConfig, 'allowSignIn', true)
      ) {
        return false;
      }

      if (
        typeof window === 'object' &&
        window.innerWidth < 600 &&
        _get(main, 'clientDetails.metadata.hideMobileAuth')
      ) {
        return false;
      }

      const signInStatus = _get(main, 'clientDetails.signIn');
      if (signInStatus) {
        return signInStatus !== 'Prevent';
      }
      return true;
    },
  );

const makeSelectOAuthOptions = () =>
  createSelector(
    selectMainDomain,
    substate => {
      const loginMethods = _isEmpty(substate.clientDetails)
        ? ['MS OAuth SignIn', 'Google SignIn', 'Facebook SignIn']
        : _get(substate.clientDetails, 'loginMethods') || [];

      return {
        ms: loginMethods.includes('MS OAuth SignIn'),
        google: loginMethods.includes('Google SignIn'),
        facebook: loginMethods.includes('Facebook SignIn'),
        cm: loginMethods.includes('CM SignIn'),
      };
    },
  );

const makeSelectShowingTermsOfUse = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showingTermsOfUse,
  );

const makeSelectShowingAbout = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showingAbout,
  );
const makeSelectShowingPrivacyPolicy = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showingPrivacyPolicy,
  );
const makeSelectShowingFeedback = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showingFeedback,
  );

const makeSelectFooter = () =>
  createSelector(
    selectMainDomain,
    substate => filterBySlug(substate.siteCopy, 'footer'),
  );
const makeSelectHomeHero = () =>
  createSelector(
    selectMainDomain,
    substate => filterBySlug(substate.siteCopy, 'home-hero'),
  );
const makeSelectSendMailStatus = () =>
  createSelector(
    selectMainDomain,
    substate => substate.mailSent,
  );
const makeSelectSendMailProcessing = () =>
  createSelector(
    selectMainDomain,
    substate => substate.sendMailProcessing,
  );
const makeSelectHomeHeaders = () =>
  createSelector(
    selectMainDomain,
    substate => filterBySlug(substate.siteCopy, 'home-section-headers'),
  );
const makeSelectFavoritesHeaders = () =>
  createSelector(
    selectMainDomain,
    substate => filterBySlug(substate.siteCopy, 'favorites-section-headers'),
  );
const makeSelectPrivacyPolicy = () =>
  createSelector(
    selectMainDomain,
    substate => {
      const slug =
        _get(substate.clientDetails, 'metadata.customPrivacyPolicy') ||
        'privacy';
      return (
        filterBySlug(substate.siteCopy, slug) ||
        filterBySlug(substate.siteCopy, 'privacy')
      );
    },
  );
const makeSelectTermsOfUse = () =>
  createSelector(
    selectMainDomain,
    substate => {
      const slug =
        _get(substate.clientDetails, 'metadata.customTermsOfUse') ||
        'terms-of-use';
      return (
        filterBySlug(substate.siteCopy, slug) ||
        filterBySlug(substate.siteCopy, 'terms-of-use')
      );
    },
  );
const makeSelectAbout = () =>
  createSelector(
    selectMainDomain,
    substate => {
      const slug =
        _get(substate.clientDetails, 'metadata.customAbout') || 'about';
      return (
        filterBySlug(substate.siteCopy, slug) ||
        filterBySlug(substate.siteCopy, 'about')
      );
    },
  );
const makeSelectFeedback = () =>
  createSelector(
    selectMainDomain,
    substate => filterBySlug(substate.siteCopy, 'feedback'),
  );
const makeSelectSiteCopy = () =>
  createSelector(
    selectMainDomain,
    substate => substate.siteCopy,
  );
const makeSelectSiteCopyLanguage = () =>
  createSelector(
    selectMainDomain,
    substate => substate.siteCopyLanguage,
  );
const makeSelectSiteCopyInOtherLang = () =>
  createSelector(
    selectMainDomain,
    substate => substate.siteCopyInOtherLang,
  );
const makeSelectSearchConfigInOtherLang = () =>
  createSelector(
    selectMainDomain,
    substate => substate.searchCopyInOtherLang,
  );
const makeSelectSiteConfig = () =>
  createSelector(
    selectMainDomain,
    substate => substate.siteConfig,
  );

const makeSelectProgramsByTopic = () =>
  createSelector(
    selectMainDomain,
    substate => substate.programs,
  );
const makeSelectThemes = () =>
  createSelector(
    selectMainDomain,
    substate => substate.themes,
  );
const makeSelectResourcesCountByTopic = () =>
  createSelector(
    selectMainDomain,
    substate => substate.resourcesCountByTopic,
  );
const makeSelectClientDetailsFetching = () =>
  createSelector(
    selectMainDomain,
    substate => substate.clientDetailsFetching,
  );
const makeSelectClientResources = () =>
  createSelector(
    selectMainDomain,
    substate => substate.clientResources,
  );
const makeSelectShowRegionSelector = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showRegionSelector,
  );
const makeSelectRegion = () =>
  createSelector(
    selectMainDomain,
    substate => substate.region,
  );
const makeSelectShowRegionBanner = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showRegionBanner,
  );
const makeSelectLandingMenu = () =>
  createSelector(
    selectMainDomain,
    substate => substate.landingMenu,
  );
const makeSelectShowHomepagePro = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showHomepagePro,
  );
const makeSelectAudienceTagsRelations = () =>
  createSelector(
    selectMainDomain,
    substate => substate.audienceTagsRelations,
  );
const makeSelectNPSVisibility = () =>
  createSelector(
    selectMainDomain,
    substate => substate.npsVisibility,
  );
const makeSelectNPSPlayCount = () =>
  createSelector(
    selectMainDomain,
    substate => substate.npsPlayCount,
  );
const makeSelectNPSPreconditions = () =>
  createSelector(
    selectMainDomain,
    substate => substate.npsPreconditions,
  );

const makeSelectHelpfulLocation = () =>
  createSelector(
    selectMainDomain,
    substate => substate.helpfulLocation,
  );

const makeSelectSaveRemindMeLaterResourceResult = () =>
  createSelector(
    selectMainDomain,
    substate => substate.saveRemindMeResourceResult,
  );

const makeSelectGetRemindMeLaterResourceResult = () =>
  createSelector(
    selectMainDomain,
    substate => substate.getRemindMeResourceResult,
  );

const makeSelectPageIntros = () =>
  createSelector(
    selectMainDomain,
    substate => substate.pageIntros,
  );

const makeSelectLanguage = () =>
  createSelector(
    selectMainDomain,
    substate => substate.language,
  );
const makeSelectShowLanguageBanner = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showLanguageBanner,
  );

const makeSelectLocales = () =>
  createSelector(
    selectMainDomain,
    substate => substate.locales,
  );
const makeSelectLeavingModal = () =>
  createSelector(
    selectMainDomain,
    substate => substate.leavingModal,
  );
const makeSelectAICCUser = () =>
  createSelector(
    selectMainDomain,
    substate => substate.aiccUser,
  );
const makeSelectAICCRedirected = () =>
  createSelector(
    selectMainDomain,
    substate => substate.aiccRedirected,
  );

const makeSelectHomeReferrals = () =>
  createSelector(
    selectMainDomain,
    substate => substate.homeReferrals,
  );

const makeSelectReferrals = () =>
  createSelector(
    selectMainDomain,
    substate => substate.referrals,
  );
const makeSelectClinicMode = () =>
  createSelector(
    selectMainDomain,
    substate => substate.clinicMode,
  );

const makeSelectShowOnboardingQuestions = () =>
  createSelector(
    selectMainDomain,
    substate => substate.showOnboardingQuestions,
  );

const makeSelectInsuranceModal = () =>
  createSelector(
    selectMainDomain,
    substate => substate.insuranceModal,
  );

const makeSelectPersonalEmailModal = () =>
  createSelector(
    selectMainDomain,
    substate => substate.personalEmailModal,
  );

const insuranceOptionsSelector = createSelector(
  [
    state => state.main.clientDetails,
    state => state.main.language,
    state => state.main.siteConfig,
  ],
  (clientDetails, language, siteConfig) => {
    const shortName = _get(clientDetails, 'shortName');
    const locale = language;

    const insuranceSiteConfig = filterByTitle(siteConfig, 'Insurance');
    const insuranceOptions = _get(
      insuranceSiteConfig,
      'config.options',
      [],
    ).filter(item => {
      if (item.exclude.includes(shortName) || item.assessmentOnly) return false;
      if (!_isEmpty(item.client)) {
        if (item.client.includes(shortName)) return true;
        return false;
      }
      return true;
    });

    return insuranceOptions.map(option => ({
      ...option,
      label: _get(option, ['label', locale], option.value),
    }));
  },
);

const makeSelectFirestoreStatus = () =>
  createSelector(
    selectMainDomain,
    substate => substate.firestoreStatus,
  );

const makeSelectContentBlocks = () =>
  createSelector(
    selectMainDomain,
    substate => substate.contentBlocks,
  );

const makeSelectIsEndingSession = () =>
  createSelector(
    selectMainDomain,
    substate => substate.isEndingSession,
  );

const makeSelectIsFreshworksLoaded = () =>
  createSelector(
    selectMainDomain,
    substate => substate.isFreshworksLoaded,
  );

const makeSelectTextDirection = () =>
  createSelector(
    selectMainDomain,
    substate => substate.textDirection,
  );

const getModeConfig = state => {
  const { featuresConfig } = state.main;
  if (!featuresConfig) {
    return null;
  }

  return state.main.clinicMode
    ? _get(featuresConfig, 'mode.publicDevice', null)
    : _get(featuresConfig, 'mode.default', null);
};

const getIsPracticesEnabled = state => {
  const { featuresConfig } = state.main;
  return !!featuresConfig && !featuresConfig?.disablePractices;
};

const makeSelectIsResourceRatingEnabled = () =>
  createSelector(
    state => state.main.siteConfig,
    siteConfig =>
      !siteConfig.find(s => s.title === 'Features')?.config?.disableCardRating,
  );

const isAIDescriptionEnabled = state => {
  const features = state.main.siteConfig.find(s => s.title === 'Features');
  if (!features || features.config?.disableAIDescription) {
    return false;
  }

  const aiSteConfig = state.main.siteConfig.find(c => c.title === 'AI Content');
  return isClientFeatureAllowed(aiSteConfig, state.main.clientDetails);
};

export {
  makeSelectProcessing,
  makeSelectClientDetailsFetching,
  makeSelectClientDetails,
  makeSelectClientDetailsLoaded,
  makeSelectAllowSignIn,
  makeSelectOAuthOptions,
  makeSelectShowingAbout,
  makeSelectShowingTermsOfUse,
  makeSelectShowingPrivacyPolicy,
  makeSelectShowingFeedback,
  makeSelectFooter,
  makeSelectHomeHero,
  makeSelectSendMailStatus,
  makeSelectSendMailProcessing,
  makeSelectHomeHeaders,
  makeSelectFavoritesHeaders,
  makeSelectSiteCopy,
  makeSelectSiteCopyLanguage,
  makeSelectSiteCopyInOtherLang,
  makeSelectSearchConfigInOtherLang,
  makeSelectSiteConfig,
  makeSelectPrivacyPolicy,
  makeSelectTermsOfUse,
  makeSelectAbout,
  makeSelectFeedback,
  makeSelectProgramsByTopic,
  makeSelectThemes,
  makeSelectResourcesCountByTopic,
  makeSelectClientResources,
  makeSelectShowRegionSelector,
  makeSelectRegion,
  makeSelectShowRegionBanner,
  makeSelectLandingMenu,
  makeSelectShowHomepagePro,
  makeSelectAudienceTagsRelations,
  makeSelectNPSVisibility,
  makeSelectNPSPlayCount,
  makeSelectNPSPreconditions,
  makeSelectHelpfulLocation,
  makeSelectSaveRemindMeLaterResourceResult,
  makeSelectGetRemindMeLaterResourceResult,
  makeSelectPageIntros,
  makeSelectReferrals,
  makeSelectHomeReferrals,
  makeSelectLanguage,
  makeSelectShowLanguageBanner,
  makeSelectLocales,
  makeSelectLeavingModal,
  makeSelectAICCUser,
  makeSelectAICCRedirected,
  makeSelectClinicMode,
  makeSelectShowOnboardingQuestions,
  makeSelectInsuranceModal,
  makeSelectPersonalEmailModal,
  makeSelectFirestoreStatus,
  makeSelectContentBlocks,
  makeSelectIsEndingSession,
  makeSelectIsFreshworksLoaded,
  makeSelectTextDirection,
  insuranceOptionsSelector,
  getModeConfig,
  isAIDescriptionEnabled,
  makeSelectIsResourceRatingEnabled,
  getIsPracticesEnabled,
};
