/**
 *
 * AssessmentItemSmall
 *
 */

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _classNames from 'classnames';
import _get from 'lodash/get';
import _has from 'lodash/has';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeSelectAuth } from 'containers/Auth/selectors';
import { trackFavoriteAction } from 'containers/Favorites/utils';
import {
  makeSelectAllowSignIn,
  makeSelectTextDirection,
} from 'containers/Main/selectors';
import HeartEmptyIcon from 'components/SaveAsFavorite/images/heart-empty.svg';
import HeartFullIcon from 'components/SaveAsFavorite/images/heart-full.svg';
import Mixpanel from 'utils/mixpanelService';
import UserInteractionService from 'utils/userInteractionService';
import { saveAssessment } from 'containers/Assessments/actions';
import {
  getColorsConfig,
  isAssessmentSaved,
  isAssessmentTaken,
} from 'containers/Assessments/utils';
import {
  makeSelectSavedAssessments,
  makeSelectTakenAssessments,
} from 'containers/Assessments/selectors';
import useAlgoliaLocale from 'components/useAlgoliaLocale';
import useSiteCopySelector from 'components/useSiteCopySelector';
import useWindowSize from 'components/useWindowSize';
import { getAlgoliaLocalizedField } from 'utils/localeUtils';
import { isBot, getImageFile } from 'utils/stringUtils';

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: 'relative',
  },
  openWrapper: {
    width: '200%',
    zIndex: 8,
    left: '-50%',
  },
  rightWrapper: {
    left: 0,
  },
  leftWrapper: {
    left: '-100%',
  },
  dataWrapper: {
    position: 'relative',
    height: '100%',
  },
  container: {
    overflow: 'hidden',
    borderRadius: '5px !important',
    border: `1px solid #E9ECED`,
    height: 271,
    width: 'auto',
    position: 'relative',
  },
  menuCardContainer: {
    height: 318,
    width: 260,
  },
  background: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  duration: {
    position: 'absolute',
    height: 21,
    left: 10,
    top: 9,
    paddingLeft: 10,
    paddingRight: 10,
    zIndex: 2,
    backgroundColor: theme.palette.primary.main,
    ...theme.typography.pxSmall,
    color: '#FFFFFF',
    borderRadius: 2,
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    position: 'absolute',
    top: 5,
    right: 8,
    display: 'flex',
    padding: 4,
    alignItems: 'center',
    justifyContent: 'center',
    width: 28,
    height: 28,
    zIndex: 2,
    '&:hover': {
      backgroundColor: 'inherit',
    },
  },
  openContentWrapper: {
    '&:first-child': {
      borderRight: '1px solid transparent',
    },
    '&:last-child': {
      borderLeft: '1px solid transparent',
    },
  },
  icon: {
    width: '100%',
    height: '100%',
    padding: 0,
    borderRadius: 0,
    backgroundColor: 'transparent',
    '&:hover': {
      background: 'none',
    },
  },
  infoWrapper: {
    backgroundColor: ({ color, useClientImage }) =>
      useClientImage ? 'inherit' : color.main,
    padding: '12px 10px',
    width: '100%',
    color: ({ color }) => color.font,
  },
  type: {
    textAlign: 'left',
    color: 'inherit',
  },
  assessmentIcon: {
    width: 12,
    height: 12,
    marginRight: 4,
  },
  name: {
    display: 'flex',
    fontSize: '0.875rem',
    color: ({ color }) => color.font,
    direction: ({ textDirection }) => textDirection,
    justifyContent: ({ textDirection }) =>
      textDirection === 'rtl' ? 'flex-end' : 'flex-start',
  },
  takeAction: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    ...theme.typography.button,
    fontSize: '0.75rem',
  },
  takeArrow: {
    marginLeft: 4,
  },
  imageWrapper: {
    flex: 1,
    overflowY: 'auto',
    position: 'relative',
  },
  takenLabel: {
    ...theme.typography.pSmallBold,
    fontSize: '0.75rem',
    position: 'absolute',
    right: 11,
    bottom: 13,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    color: '#FFFFFF',
    borderRadius: 2,
    textTransform: 'initial',
    padding: '3px 9px',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 1)',
    },
  },
  assessmentImage: {
    height: '100%',
    width: '100%',
    objectFit: ({ withObjectFit }) => withObjectFit && 'cover',
  },
  grayscale: {
    filter: 'grayscale(1)',
  },
}));

const getImageData = ({ item, useAltImage, useClientImage }) => {
  const isAlgoliaItem = !!item.objectID;
  if (isAlgoliaItem) {
    return {
      imageUrl: useAltImage ? item.altImageUrl || item.imageUrl : item.imageUrl,
    };
  }
  const clientImage = useClientImage ? _get(item, 'clientImage.0') : null;

  return {
    image:
      useClientImage && clientImage
        ? clientImage
        : useAltImage
        ? item.altImage || item.image
        : item.image,
  };
};

const OBJECT_FIT_RATIO = 1.9;
const ALT_IMAGE_PERCENTAGE_RATIO = 5;

const AssessmentItemSmall = ({
  item,
  item: { time, slug, name = '', callToAction = '' },
  listTitle,
  recommendation,
  expanded,
  menuCard,
  grayscale,
  useClientImage,
  durationAltPosition = false,
  trackingProps = {},
  onRedirect,
}) => {
  const [open] = useState(expanded);
  const hasTracked = useRef(false);
  const wrapperRef = useRef();
  const imageWrapperRef = useRef();
  const dispatch = useDispatch();
  const savedAssessments = useSelector(makeSelectSavedAssessments());
  const takenAssessments = useSelector(makeSelectTakenAssessments());
  const textDirection = useSelector(makeSelectTextDirection());
  const history = useHistory();
  const location = useLocation();
  const auth = useSelector(makeSelectAuth());
  const allowSignIn = useSelector(makeSelectAllowSignIn());
  const locale = useAlgoliaLocale();
  const [
    resourceItemPageSiteCopy,
    allAssessmentsSiteCopy,
  ] = useSiteCopySelector(['resource-item-page', 'all-assessments']);

  const windowSize = useWindowSize();
  const [{ useAltImage, withObjectFit }, setImageSettings] = useState({
    useAltImage: true,
    withObjectFit: true,
  });

  const { color, takeArrowSvg, checkSvg } = getColorsConfig(item);
  const imageData = getImageData({ item, useAltImage, useClientImage });
  const imageUrl = getImageFile(imageData);

  const classes = useStyles({
    color,
    imageUrl,
    withObjectFit,
    useClientImage,
    textDirection,
  });

  const saved = isAssessmentSaved(savedAssessments, {
    id: item.objectID || item.id,
  });
  const taken = isAssessmentTaken(takenAssessments, {
    id: item.objectID || item.id,
  });

  useEffect(() => {
    const imageWrapper = imageWrapperRef.current;
    if (!imageWrapper) {
      return;
    }

    const width = imageWrapper.clientWidth;
    const height = imageWrapper.clientHeight;
    const imageRatio = width / height;
    const isAltImage = (imageRatio - 1) * 100 >= ALT_IMAGE_PERCENTAGE_RATIO;
    const isObjectFit = imageRatio < OBJECT_FIT_RATIO;

    setImageSettings({
      useAltImage: isAltImage,
      withObjectFit: isObjectFit,
    });
  }, [windowSize]);

  const handleSave = e => {
    e.preventDefault();
    e.stopPropagation();

    const id = item.objectID || item.id;

    let nodeID;
    if (saved) [{ nodeID }] = savedAssessments.filter(el => el.sys.id === id);

    const payload = {
      nodeID,
      assessment: {
        assessmentID: id,
        slug,
        name,
        callToAction,
        time,
      },
      save: !saved,
    };

    const pageName = location.pathname.split('/')[1];
    const finalPageName = `${pageName} Page`;
    trackFavoriteAction(
      'Card Click',
      {
        page: finalPageName,
        type: 'Assessments',
        resourceID: id,
        slug,
        name,
      },
      auth,
      saved,
    );
    dispatch(saveAssessment(payload));
  };

  const redirect = (event, isTaken) => {
    const pageName = location.pathname.split('/')[1];
    const finalPageName = pageName ? `${pageName}Page` : 'homePage';
    Mixpanel.track(event, {
      name,
      type: 'assessment',
      page: finalPageName,
      listTitle,
      path: location.pathname.split('/')[1],
      slug: location.pathname.split('/')[2],
      entryId: item.objectID || item.id,
      taken: isTaken,
      recommendation,
      ...trackingProps,
    });

    const redirectUrl =
      event === 'Get Your Results' && isTaken
        ? `/assessments/${slug}/results`
        : `/assessments/${slug}`;

    if (onRedirect) {
      onRedirect(redirectUrl);
    } else {
      history.push(redirectUrl);
    }
  };

  const handleCardClick = e => {
    e.preventDefault();
    e.stopPropagation();

    redirect('Resource Card - Clicked', taken);
  };

  const handleTakeAssessment = e => {
    e.preventDefault();
    e.stopPropagation();

    redirect('Take Assessment', taken);
  };

  const handleTakenButton = e => {
    e.preventDefault();
    e.stopPropagation();

    redirect('Get Your Results', taken);
  };

  const duration = `${time} ${
    time < 2
      ? _get(allAssessmentsSiteCopy, 'pageCopy.minSingular', 'MIN')
      : _get(allAssessmentsSiteCopy, 'pageCopy.minPlural', 'MINS')
  }`;

  const bot = isBot();

  const resourceAudiences = [];
  _get(item, 'audienceType', _get(item, 'audience', [])).forEach(entry => {
    if (
      _has(entry, 'fields.reviewStatus') &&
      _get(entry, 'fields.reviewStatus') !== 'Accepted'
    )
      return;

    const audienceName = _get(entry, 'fields.name', entry);
    resourceAudiences.push(audienceName);
  });
  const resourceTags = [];
  _get(item, 'fields.tags', _get(item, 'audience', [])).forEach(entry => {
    if (
      _has(entry, 'fields.reviewStatus') &&
      _get(entry, 'fields.reviewStatus') !== 'Accepted'
    )
      return;

    const tagTitle = _get(entry, 'fields.title', entry);
    resourceTags.push(tagTitle);
  });

  const shouldShowSave = !menuCard && !bot && allowSignIn;

  return (
    <div
      className={_classNames(classes.wrapper, {
        [classes.openWrapper]: !expanded && open,
      })}
      ref={wrapperRef}
    >
      <ButtonBase disableRipple onClick={handleCardClick}>
        <Paper
          elevation={0}
          className={_classNames(classes.container, {
            [classes.menuCardContainer]: menuCard,
          })}
          onMouseEnter={() => {
            if (!hasTracked.current) {
              hasTracked.current = true;
              UserInteractionService.registerGeneralInteraction({
                type: 'resource-card-hovered',
                resourceID: item.objectID || item.id,
                resourceAudiences: resourceAudiences.join(),
                resourceTags: null,
                resourceType: 'assessment',
              });
            }
          }}
        >
          <Grid container className={classes.fullHeight}>
            <Grid item xs={12} className={_classNames(classes.dataWrapper)}>
              {!durationAltPosition && (
                <Typography className={classes.duration}>{duration}</Typography>
              )}
              {shouldShowSave && (
                <>
                  {saved && (
                    <IconButton
                      className={classes.iconButton}
                      onClick={handleSave}
                      component="span"
                      disableRipple
                      disableFocusRipple
                      aria-label="Unsave"
                    >
                      <img
                        src={HeartFullIcon}
                        alt="Full"
                        className={classes.icon}
                      />
                    </IconButton>
                  )}
                  {!saved && (
                    <IconButton
                      className={classes.iconButton}
                      onClick={handleSave}
                      component="span"
                      disableRipple
                      disableFocusRipple
                      aria-label="Unsave"
                    >
                      <img
                        src={HeartEmptyIcon}
                        alt="Empty"
                        className={classes.icon}
                      />
                    </IconButton>
                  )}
                </>
              )}
              <div className={classes.background}>
                <div className={classes.imageWrapper} ref={imageWrapperRef}>
                  <img
                    src={imageUrl}
                    className={_classNames({
                      [classes.assessmentImage]: true,
                      [classes.grayscale]: grayscale,
                    })}
                    alt={name}
                  />
                  {taken && (
                    <Button
                      variant="text"
                      component="span"
                      onClick={handleTakenButton}
                      className={_classNames(classes.takenLabel, {
                        [classes.grayscale]: grayscale,
                      })}
                    >
                      {_get(
                        resourceItemPageSiteCopy,
                        'pageCopy.takenAssessmentLabel',
                      )}
                      <img
                        src={takeArrowSvg}
                        alt=""
                        className={classes.takeArrow}
                      />
                    </Button>
                  )}
                </div>
                <div className={classes.infoWrapper}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="overline"
                        component="p"
                        className={classes.type}
                      >
                        <img
                          src={checkSvg}
                          alt=""
                          className={classes.assessmentIcon}
                        />
                        {_get(
                          resourceItemPageSiteCopy,
                          'pageCopy.resourceCardTypeMapping.Assessment',
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h6"
                        className={classes.name}
                        gutterBottom
                        align="left"
                      >
                        {getAlgoliaLocalizedField(item, 'name', locale)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonBase
                        variant="text"
                        disableRipple
                        component="span"
                        onClick={handleTakeAssessment}
                        classes={{
                          root: classes.takeAction,
                        }}
                      >
                        {_get(resourceItemPageSiteCopy, [
                          'pageCopy',
                          taken
                            ? 'retakeAssessmentLabel'
                            : 'takeAssessmentLabel',
                        ])}
                        {durationAltPosition && ` (${duration})`}
                        <img
                          src={takeArrowSvg}
                          alt=""
                          className={classes.takeArrow}
                        />
                      </ButtonBase>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
      </ButtonBase>
    </div>
  );
};
AssessmentItemSmall.propTypes = {
  item: PropTypes.object,
  recommendation: PropTypes.bool,
  expanded: PropTypes.bool,
  listTitle: PropTypes.string,
  menuCard: PropTypes.bool,
  grayscale: PropTypes.bool,
  useClientImage: PropTypes.bool,
};

AssessmentItemSmall.defaultProps = {
  recommendation: false,
  expanded: false,
  menuCard: false,
};

export default AssessmentItemSmall;
